var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card product_card"},[_c('img',{staticClass:"one_product_image",attrs:{"src":_vm.image,"alt":_vm.name,"loading":"lazy"}}),_c('div',{staticClass:"top-right2"},[_c('a',{on:{"click":_vm.changeFav}},[(this.fav)?_c('i',{staticClass:"fa fa-heart font-xs love",staticStyle:{"color":"red"},attrs:{"id":"faPlus"}}):_c('i',{staticClass:"fa fa-heart-o font-xs love",attrs:{"id":"faPlus"}})])]),_c('div',{staticClass:"top-left"},[(this.login)?_c('a',{staticClass:"login_product_msg",attrs:{"href":"javascript:void(0)"}},[_vm._v(_vm._s(_vm.$t('loginMessage1'))+" "+_vm._s(_vm.$t('loginMessage2'))+" ")]):_vm._e(),(this.msg_fav)?_c('a',{staticClass:"login_product_msg",attrs:{"href":"javascript:void(0)"}},[_vm._v(" "+_vm._s(this.msg_fav)+" ")]):_vm._e()]),_c('h6',{staticClass:"title_card"},[_vm._v(_vm._s(_vm.name))]),_c('div',{staticClass:"d-flex justify-content-between desc_card"},[(_vm.is_option)?_c('p',[_vm._v(_vm._s(_vm.$t('PriceOnRequest')))]):((_vm.new_price>0)&&(!_vm.is_option))?_c('p',[_vm._v(_vm._s(_vm.new_price.toFixed(_vm.$store.state.digit_price))+_vm._s(_vm.$store.state.UnitPrice)+" "),_c('del',{staticClass:"text-slate-400"},[_vm._v(_vm._s(_vm.old_price.toFixed(_vm.$store.state.digit_price))+_vm._s(_vm.$store.state.UnitPrice))])]):(!_vm.is_option)?_c('p',[_vm._v(_vm._s(_vm.old_price.toFixed(_vm.$store.state.digit_price))+" "+_vm._s(_vm.$store.state.UnitPrice))]):_vm._e(),_c('router-link',{staticStyle:{"margin-right":"4%"},attrs:{"to":{
                            name: 'product',
                            params: {
                            storeName: _vm.$store.storeName,
                            lang:_vm.$store.state.routeLang,
                             id: _vm.productid  ,
                              name:_vm.name
                                       },
                              }}},[_c('button',{staticClass:"add_cart_btn"},[_c('i',{staticClass:"fa fa-plus"})])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }