<template>
  <div>
      <!-- TAGLINE START-->
      <div id="topbar" :class="'tagline bg-white dark:bg-slate-900 '
      +this.$store.state.topbar">
          <div class="container relative">                
              <div class="grid grid-cols-1">
                  <div class="text-center">
                      <h6 class="text-slate-900 dark:text-white font-semibold">
                          {{ $t('Refer a friend & get $50 in credits each 🎉') }}
                          </h6>
                  </div>
              </div>
          </div><!--end container-->
      </div><!--end tagline-->
    <!-- Start Navbar -->
    <nav id="topnav" 
    :class="'defaultscroll is-sticky tagline-height light '+this.$store.state.navbar">
          <div class="container relative">
              <!-- Logo container-->
              <router-link class="logo"
              :to="{
                            name: 'home',
                           params: { storeName: this.$store.storeName ,lang: $store.state.routeLang},
                                   }"
              >
                  <span class="inline-block dark:hidden">
                      <img 
                      :src="this.$store.state.logo" class="l-dark" 
                      :alt="this.$store.storeName">
                      <img 
                      :src="this.$store.state.logo" class="l-light"
                      :alt="this.$store.storeName">
                  </span>
                  <img 
                  :src="this.$store.state.logo" class="hidden dark:inline-block" 
                  :alt="this.$store.storeName">
              </router-link>
              <!-- End Logo container-->

              <!-- Start Mobile Toggle -->
              <div class="menu-extras">
                  <div class="menu-item">
                      <a class="navbar-toggle" 
                      id="isToggle" 
                      @click="toggleMenu()">
                          <div class="lines">
                              <span></span>
                              <span></span>
                              <span></span>
                          </div>
                      </a>
                  </div>
              </div>
              <!-- End Mobile Toggle -->

              <!--Login button Start-->
              <ul class="buy-button list-none mb-0">
                  <li class="dropdown inline-block relative pe-1">
                    <b-dropdown size="lg"  variant="link" toggle-class="text-decoration-none" no-caret>
                      <template #button-content>
                      <button 
                     
                      class=" 
                      align-middle 
                      inline-flex 
                      search-dropdown
                      " type="button">
                          <i v-html="search"
                           class="size-5 dark-icon"></i>
                          <i v-html="search" 
                          class="size-5 white-icon text-white"></i>
                      </button>
                    </template>
                      <!-- Dropdown menu -->
                      <b-dropdown-item >
                      <div id="search_form" class="absolute 
                      overflow-hidden end-0 m-0 mt-5
                       z-10 md:w-52 w-48 rounded-md bg-white 
                       dark:bg-slate-900 shadow 
                       dark:shadow-gray-800 ">
                          <div class="relative">
                              <i
                              @click="searchRoute"
                               v-html="search"
                               class="size-4 absolute top-[9px] end-3"></i>
                              <input 
                               class="h-9 px-3 pe-10 w-full 
                               border-0 focus:ring-0 outline-none
                                bg-white dark:bg-slate-900 shadow dark:shadow-gray-800"
                               :placeholder="$t('Search...')"
                               id="search-input"
                               autocomplete="false"
                             onblur="this.setAttribute('readonly', 'readonly') , word=''"
                             onfocus="this.removeAttribute('readonly');"
                             :aria-label="$t('search')"
                            aria-describedby="search-addon"
                           type="text"
                          v-model="word"
                                @keyup.enter="searchRoute"
                               >
                          </div>
                      </div>
                    </b-dropdown-item>
                      </b-dropdown>
                  </li>

                  <li  class="dropdown inline-block relative ps-0.5">
                    <div
                       
                       style="
                
        color: white;
        font-size: 9px;
        top: 2px;
        right: 16px;
        position: absolute;
        display: inline;
                     "
                     >{{this.$store.state.cartCounter}}</div>
                      <!-- Dropdown menu -->
  <b-dropdown size="lg"  
  variant="link" 
  toggle-class="text-decoration-none" 
  no-caret 
  bv::dropdown::hide	
  >
    <template #button-content>
      <button
                        class="size-9 inline-flex 
                        items-center justify-center tracking-wide 
                        align-middle duration-500 text-base text-center 
                        rounded-full bg-orange-500 border border-orange-500 
                        text-white" type="button"
                       @click="show_cart_fun()"
                        >
                            <i v-html="shopping_cart" 
                            style="width: 20px;
                                   height: 20px;"
                            class="h-1 w-1"></i>
                            

                        </button>
                        
                        
    </template>
   
    <b-dropdown-item class="absolute
                         end-0 m-0 mt-4 z-10 w-64 rounded-md
                          bg-white dark:bg-slate-900 
                          shadow dark:shadow-gray-800" 
                          >
                    <ul class="py-3 text-start" >
                                <li 
                              
                                v-for="(item, index) in this.products_list"
                                
                                class="dropdown-menu show_cart">
                                  <!--  -->
                                  <router-link 
                                        :to="{
                                       name: 'product',
                                      params: { storeName: $store.storeName, 
                                        id:item.id ,
                                         name:item.name,
                                         lang:$store.state.routeLang
                                         },
                                             }"

  class="flex items-center justify-between py-1.5 px-4">


                                        <span class="flex items-center">
                                            <img 
                                            :src="item.image" 
                                           :alt="item.name"
                                            class="rounded shadow 
                                            dark:shadow-gray-800 w-9 
                                            item_shoping_header_img"
                                             >
                                            <span class="ms-3">
                                                <span class="block font-semibold">
                                                  {{item.name}}</span>
                                                <span class="block text-sm 
                                                text-slate-400"
                                                v-if="items[index].offer_price != 0"
                                    >
                                    {{ (items[index].offer_price/items[index].quantity).toFixed(2)}}
                                              X{{ items[index].quantity}}</span>

                                              <span class="block text-sm text-slate-400"
                                                v-else
                                    >
                                    {{ (items[index].total_price/items[index].quantity).toFixed(2)}}
                                              X{{ items[index].quantity}}</span>
                                            </span>


                                            </span>

                                        <span   v-if="items[index].offer_price != 0"
                                         class="font-semibold">
                                         {{ items[index].offer_price.toFixed(2)}}</span>
                                        <span v-else class="font-semibold">
                                          {{items[index].total_price.toFixed(2)}}</span>
                                          </router-link>
                                <!--  -->
                                 </li>   

                                <li v-if="products_list.length==0"
                                class="dropdown-menu show_cart">
                                  {{$t('empty')}}
                                </li>      

                                <li class="dropdown-menu show_cart border-t border-gray-100 dark:border-gray-800 my-2"></li>

                                <li class=" show_cart flex items-center justify-between py-1.5 px-4">
                                    <h6 class="font-semibold mb-0">{{$t('total')}} </h6>
                                    <h6 class="font-semibold mb-0">{{price.toFixed(2)}} {{this.$store.state.UnitPrice}}</h6>
                                </li>
                                  
                                <li class="dropdown-menu show_cart py-1.5 px-4">
                                    <span class="text-center block">
                                        <a href="javascript:void(0)"
                                         class="btn_header_cart py-[5px] px-4 inline-block
                                          font-semibold tracking-wide
                                          align-middle duration-500
                                          text-sm text-center rounded-md
                                           bg-orange-500 border border-orange-500
                                            text-white">
                                            <router-link
                                            to="/shopping_cart"
                                            >
                                            {{$t('View Cart')}}
                                            </router-link>
                                        </a>
                                        <a v-if="this.$localStorage.get(this.$store.storeName) != null"
                                        href="javascript:void(0)" 
                                        class="btn_header_cart py-[5px] px-4 inline-block
                                         font-semibold tracking-wide
                                        align-middle duration-500 text-sm
                                        text-center rounded-md bg-orange-500
                                        border border-orange-500 text-white">
                                        <router-link
                                            to="/checkout"
                                            >
                                    {{ $t('Checkout') }}
                                    </router-link>
                                    </a>
                                    <a
                                    v-else
                                     href="javascript:void(0)" 
                                        class="btn_header_cart py-[5px] px-4 inline-block
                                         font-semibold tracking-wide
                                        align-middle duration-500 text-sm
                                        text-center rounded-md bg-orange-500
                                        border border-orange-500 text-white ">
                                        <router-link
                                            to="/login"
                                           
                                            >{{ $t('login cart') }}
                                    </router-link>
                                    </a>
                                    </span>
                                    <p class="text-sm text-slate-400 mt-1"></p>
                                </li>
                            </ul>
    </b-dropdown-item>
  </b-dropdown>
                  </li>

                  <li  v-if="this.$localStorage.get(this.$store.storeName) != null" class="inline-block ps-0.5">
                      <a href="javascript:void(0)" 
                      class="favourite_btn size-9 inline-flex items-center 
                      justify-center tracking-wide align-middle
                       duration-500 text-base text-center rounded-full
                        bg-orange-500 text-white">
                        <router-link to="/favorites"  @click="activeMobile = false">
                          <i v-html="heart" class="h-3 w-3"></i>
                          </router-link>
                      </a>
                  </li>
          
                  <li v-if="this.$localStorage.get(this.$store.storeName) != null" 
                  class=" inline-block relative ps-0.5">
                    <b-dropdown size="lg"  variant="link" 
                    toggle-class="text-decoration-none" no-caret>
                    <template #button-content>
                     
                        <button data-dropdown-toggle="dropdown" 
                        class="dropdown-toggle items-center" type="button">
                          
                              <span class="size-9 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center rounded-full border border-orange-500 bg-orange-500 text-white">
                                <img src="https://www.gazaliperfumes.com/theme8/images/client/16.jpg" 
                                class="rounded-full" alt="gazaliperfumes"></span>
                          
                        </button>
                  </template>
                    <b-dropdown-item 
                    id="user_account" 
                    class="absolute end-0 m-0 mt-4 z-10 w-48 
                    rounded-md overflow-hidden bg-white 
                    dark:bg-slate-900 shadow dark:shadow-gray-700"
                    >
                      
                            <ul class="py-2 text-start">
                                <li>
                                    <p class="text-slate-400 pt-2 px-4">{{$t('Welcome')}} {{this.$localStorage.get(this.$store.user_name) }}!</p>
                                </li>
                                
                                <li>
                                    <router-link 
                                    :to="{
                               name: 'profile',
                              params: { storeName: this.$store.storeName,lang:$store.state.routeLang },
                                   }"
                                    class="flex items-center font-medium py-2 px-4 dark:text-white/70 hover:text-orange-500 dark:hover:text-white">
                                        <i v-html="user" class="h-4 w-4 me-2"></i>{{$t('My Account')}}</router-link>
                                </li>
                                <li>
                                    <router-link :to="{
                                           name: 'myOrders',
                                           params: { storeName: this.$store.storeName,lang:$store.state.routeLang },
                                                 }" class="flex items-center font-medium py-2 px-4 dark:text-white/70 hover:text-orange-500 dark:hover:text-white">
                                      <i v-html="shopping_cart" class="h-4 w-4 me-2"></i>
                                      {{ $t('myOrders') }}</router-link>
                                </li>
                                <li>
                                     <router-link 
                                            :to="{
                                           name: 'notifications',
                                           params: { storeName: this.$store.storeName,lang:$store.state.routeLang },
                                                 }" 
                                                 class="flex items-center font-medium
                                                  py-2 px-4 dark:text-white/70 hover:text-orange-500
                                                   dark:hover:text-white">
                                                   <i v-html="help_circle" class="h-4 w-4 me-2">
                                                    </i>{{$t('Notifications')}}</router-link>
                                </li>
                                <li class="border-t 
                                border-gray-100 dark:border-gray-800 my-2"></li>
                                <li  @click="show = true" >
                                    <a  class="flex items-center font-medium py-2 
                                    px-4 dark:text-white/70 hover:text-orange-500 dark:hover:text-white">
                                    <i v-html="log_out" class="h-4 w-4 me-2"></i>{{$t('logout')}}</a>
                                </li>
                               
                            </ul>

                    </b-dropdown-item>
  
                    </b-dropdown>
                      <!-- Dropdown menu -->
                     
                  </li>
                  
                  <li  @click="activeMobile = false" v-if="this.$localStorage.get(this.$store.storeName) == null" class="dropdown inline-block relative ps-0.5">
                      <a href="javascript:void(0)" class="login_btn size-9 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center rounded-full bg-orange-500 text-white">
                      <router-link to="/login"  class="lgin_btn_header">
                      <span>{{ $t('logIn') }}</span></router-link>
                      </a>
                      </li>

                     

                  <!--end dropdown-->
              </ul>
              <!--Login button End-->

              <div id="navigation">
                  <!-- Navigation Menu-->
                  <ul class="navigation-menu nav-light">
                      <li class="parent-menu-item"  @click="toggleMenu2()" >
                          <a   @click="toggleMenu2()" href="javascript:void(0)"> <router-link :to="{
                            name: 'home',
                           params: { storeName: this.$store.storeName,lang:$store.state.routeLang },
                                   }"
                                   >
                              {{ $t('home') }}
                              </router-link></a><span class="menu-arrow"></span>
                         
                      </li>

                      <li   @click="toggleMenu2()" class="parent-parent-menu-item">
                          <a   @click="toggleMenu2()" href="javascript:void(0)">
                              <router-link 
                              :to="{
                            name: 'sections',
                           params: { storeName: this.$store.storeName,lang:$store.state.routeLang },
                                   }"
                              >
                              {{ $t('sections') }}
                              </router-link>
                              </a><span class="menu-arrow"></span> 
                      </li>

                      <li class=" parent-parent-menu-item"  @click="toggleMenu2()">
                          <a href="javascript:void(0)"  @click="toggleMenu2()"> 
                               <router-link :to="{
                            name: 'products',
                           params: { storeName: this.$store.storeName,lang:$store.state.routeLang },
                                   }"
                                   >
                              {{ $t('products') }}
                              </router-link> </a>
                              <span class="menu-arrow"></span>
                        
                      </li>
              
                      <li class="parent-parent-menu-item"  @click="toggleMenu2()">
                          <a href="javascript:void(0)"  @click="toggleMenu2()">
                              <router-link :to="{
                            name: 'brands',
                           params: { storeName: this.$store.storeName,lang:$store.state.routeLang },
                                   }"
                                   >
                                   {{ $t('brands') }}
                                   </router-link>
                              </a><span class="menu-arrow"></span>
                      </li>
              
                      <li  @click="toggleMenu2()"><a class="sub-menu-item"  @click="toggleMenu2()"><router-link :to="{
                            name: 'about',
                           params: { storeName: this.$store.storeName,lang:$store.state.routeLang },
                                   }"
                                   >
                                   {{ $t('about') }}
                                   </router-link></a></li>

                      <li  @click="toggleMenu2()"><router-link  @click="toggleMenu2()" :to="{
                            name: 'contactUs',
                           params: { storeName: this.$store.storeName,lang:$store.state.routeLang },
                                   }" class="sub-menu-item">
                                   {{ $t('contactUs') }}
                                   </router-link>
                                   </li>


                                   <li ><a 
                                   v-if="$i18n.locale == 'ar'" @click="onChange('en');toggleMenu2()"
                                   class="sub-menu-item btn_click">
                                  English
                                   </a>
                                   <a 
                                   v-else @click="onChange('ar');toggleMenu2()"
                                   class="sub-menu-item btn_click">
                                  العربية
                                   </a>
                                   </li>
                                 
                                  
                  </ul><!--end navigation menu-->
              </div><!--end navigation-->
          </div><!--end container-->
      </nav><!--end header-->
      <!-- End Navbar -->
    <Transition>
      <div v-if="show==true" class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <div class="modal-header">
              <slot name="header">{{ $t("confirmlogout") }}</slot>
            </div>
            <div class="modal-footer">
              <slot name="footer">
                <button class="modal-default-button" @click="logOut()">{{ $t("confirm") }}</button>
                <button
                  class="modal-default-button"
                  @click="(show = false), $emit('close')"
                >{{ $t("cancel") }}</button>
              </slot>
            </div>
          </div>
        </div>
      </div>
    </Transition>
       </div>
</template>

<script>
import cart_item from "../components/cart_item_shoopping_header.vue";
export default {
  components: { cart_item},
  data: function () {
      
  return {
 show_cart:true,
    langChange:'',
    items: [],
    products: [],
    products_list:[],
    loading: false,
    show: false,
    price: 0,
    word:'',
    countries:[],
    country:6
  };
},

  computed: {
  search: function () {
    return feather.toSvg('search')
  },
  shopping_cart: function () {
    return feather.toSvg('shopping-cart')
  },
  heart: function () {
    return feather.toSvg('heart')
  },
  user: function () {
    return feather.toSvg('user')
  },
  bell: function () {
    return feather.toSvg('bell')
  },
  log_out: function () {
    return feather.toSvg('log-out')
  },
  help_circle: function () {
    return feather.toSvg('help-circle')
  },
  
},

  methods:
  {
      searchRoute() {
    if (this.$route.path.includes("/" + this.$store.storeName + "/search")) {
      this.$router.push({ name: "search", params: { word: this.word,lang: this.$i18n.locale } });
    } else {
      this.$router.push({
        name: "search",
        params: { storeName: this.$store.storeName, word: this.word,lang: this.store.state.routeLang }
      });
    }
  },
  toggleMenu() {
    document.getElementById('isToggle').classList.toggle('open');
    var isOpen = document.getElementById('navigation')
    if (isOpen.style.display === "block") {
        isOpen.style.display = "none";
    } else {
        isOpen.style.display = "block";
    }
},

toggleMenu2() {
    document.getElementById('isToggle').classList.remove('open');
    var isOpen = document.getElementById('navigation')
   isOpen.style="display:none";
},
      
      show_cart_fun(){
         
  this.loading=true;

  if(this.$i18n.locale == 'ar')
  {
      this.unit=this.$store.state.unitAR;
  }
  else
  {
      this.unit=this.$store.state.unit;
  }
  let AllCookies = this.$cookies.keys();
  this.products = [];
  this.items=[];
  this.products_list=[];
  this.price = 0;

  for (let i = 0; i < AllCookies.length; i++) {
    if (AllCookies[i].split("/")[0] == this.$store.storeName) {
     this.items.push(this.$cookies.get(AllCookies[i]));
      this.products.push(this.$cookies.get(AllCookies[i])['product_id']);

      if (this.$cookies.get(AllCookies[i]).offer_price != 0) {
        this.price += this.$cookies.get(AllCookies[i]).offer_price;
      } else {
        this.price += this.$cookies.get(AllCookies[i]).total_price;
      }
      console.log(this.items);
    }
  }
 
//////////////////////////
this.axios
        .post(
          this.$store.state.base_api_url + this.$store.storeName + "/all_cart_item",
          {
            product_id: this.products,
            
          },
          {
            headers: {
              "Accept-Language": this.$i18n.locale
            }
          }
        )
        .then(response => {
          this.products_list = response.data.data;
       
        })
        .then(() => (this.loading = false));
///////////////////////////////

     

},
show_search(){
  document.getElementById('search_form').classList.remove('hidden');
}
,onChange(lang) {
  this.$store.state.loading=true;
  this.$store.state.loading_home=true;
    this.$store.state.langchange = true;
    this.$store.state.langchange1 = true;
     //for footer
    this.$i18n.locale = lang;
    


    if (lang == "ar") {
      this.$store.state.UnitPrice=this.$store.state.unitAR
      document.documentElement.setAttribute("dir", "rtl");
      let h = document.querySelector('body');
      h.setAttribute('dir', 'rtl')

      document.title = this.$store.state.title_ar;
    document.getElementById("description_meta").content=this.$store.state.description_ar;
    document.getElementById("keywords_meta").content=this.$store.state.keyword_ar;
    this.$store.state.routeLang=null
    this.$router.replace({ params: { lang: null } })

    } else {
      this.$store.state.UnitPrice=this.$store.state.unit
      this.$store.state.routeLang='en'

      document.documentElement.setAttribute("dir", "ltr");
      let h = document.querySelector('body');
      h.setAttribute('dir', 'ltr')

      document.title = this.$store.state.title_en;
    document.getElementById("description_meta").content=this.$store.state.description_en;
    document.getElementById("keywords_meta").content=this.$store.state.keyword_en;

    this.$router.replace({ params: { lang: this.$store.state.routeLang } })
  
    }
    
    setTimeout(() => {
         this.$store.state.loading_home=false;
        }, 2500);
        setTimeout(() => {
           this.$store.state.loading=false;
        }, 800);
  
  },
  logOut() {
    this.loading = true;
    this.axios.get(
      this.$store.state.base_api_url + this.$store.storeName + "/logout",
      {
        headers: {
          "Accept-Language": this.$i18n.locale,
          Authorization:
            "Bearer " + this.$localStorage.get(this.$store.storeName)
        }
      }
    );
    //console.group("hiiiiii")
    console.log(
      "logout:" +
        //this.$localStorage.id() +
        this.$localStorage.get(this.$store.storeName)
    );
    //this.$localStorage.remove("userToken");
    this.show=false;
    let AllCookies = this.$cookies.keys();
    for (let i = 0; i < AllCookies.length; i++) {
      if (AllCookies[i].split("/")[0] == this.$store.state.storeName) {
        this.$cookies.remove(console.log(AllCookies[i]));
      }
      this.$cookies.keys().forEach(cookie => this.$cookies.remove(cookie))

    }
    console.log(AllCookies);
    this.$localStorage.remove(this.$store.storeName);
    window.location.reload();
    this.loading = false;
  },
 

  },
  created(){
  this.loading=true;
  this.$store.state.cartCounter=0;
  if (this.$route.params.lang && this.$route.params.lang != this.$i18n.locale) {
      if (this.$route.params.lang == 'en') {
        this.$router.replace({ params: { lang: this.$i18n.locale } })
        this.$i18n.locale="en"
        document.documentElement.setAttribute("dir", "ltr");
        let h = document.querySelector('body');
        this.$store.state.UnitPrice=this.$store.state.unit;
        $store.state.routeLang='en'

      }
      else{
        this.$store.state.UnitPrice=this.$store.state.unitAR;
        $store.state.routeLang=null
      }
    }
    

  let AllCookies = this.$cookies.keys();
  this.products = [];
  this.items = [];
  this.price = 0;

  for (let i = 0; i < AllCookies.length; i++) {
      console.log('ii ',i)
    if (AllCookies[i].split("/")[0] == this.$store.storeName) {
      this.items.push(AllCookies[i]);
      this.$store.state.cartCounter+=1;

      this.products.push(this.$cookies.get(AllCookies[i]));

      if (this.$cookies.get(AllCookies[i]).offer_price != 0) {
        this.price += this.$cookies.get(AllCookies[i]).offer_price;
      } else {
        this.price += this.$cookies.get(AllCookies[i]).total_price;
      }
    }
  }


  //////////////////////////

///////////////////////////////
},
watch: {

}
}
</script>