<template>
<section 
    class="relative  py-16">
        <div class="container relative ">
          <div class="grid items-end md:grid-cols-2 mb-6">
                   <div class="md:text-start text-center">
                       <h5 class="font-semibold text-3xl leading-normal mb-4">
                        {{name}}</h5>
                       <p class="text-slate-400 max-w-xl">{{$t('Shop the latest products from the most popular items')}}</p>
                   </div>

                   <div class="md:text-end hidden md:block">
                       <router-link 
                       :to="{
                              name: 'section',
                              params: { storeName: $store.storeName,
                               id: this.id  ,
                               name:this.name,
                               lang: $store.state.routeLink},
                                  }"
                        class="text-slate-400 hover:text-orange-500">{{ $t('See More Items') }} 
                        <i class="mdi mdi-arrow-right"></i></router-link>
                   </div>
               </div><!--end grid-->
             </div><!--end container-->

           <div class="container relative">
              

               <div class="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-2 pt-6 gap-6">
                 <product v-for="(item, index) in this.products"
                 :key="index"  
                 
             :name="item.name"
             :description="item.description"
             :is_favorit="item.is_favorit"
             :image="item.thump"
             :old_price="item.old_price"
             :new_price="item.new_price"
             :productid="item.id"
             :rate="item.rate"
             :count_view="item.count_views"
             :is_option="item.is_option"
             :is_offer="item.is_offer"
              :unit="item.unit"
              :new="item.is_new"
              :fetuered="item.is_fetured"
              :discount="item.discount"
              :qnt="item.qnt"
              :min_qnt="item.min_qnt"
              :max_qnt="item.max_qnt"
              :range_price="item.range_price"
              :note="item.note"
       
       >
 
         </product>
                   <!--end content-->

               </div><!--end grid-->

               <div class="md:text-end show_all md:block">
            <router-link  :to="{
                              name: 'section',
                              params: { storeName: $store.storeName,
                               id: this.id  ,
                               name:this.name,
                               lang: $store.state.routeLink},
                                  }"
                      class="text-slate-400 hover:text-orange-500">
              {{ $t('See More Items') }} <i class="mdi mdi-arrow-right"></i></router-link>
          </div>

           </div><!--end container-->
        

       </section><!--end section-->
</template>

<script>
import product from "../components/one_product_list.vue";

export default {
  components: {product},
  props: {
    name: String,
    id: Number,
    products:Array
   

  },
  data: function () {
   
    return {
      loading:false,
      langChange:'',
    new_products :[]
    };
  },
  created() {
    // this.new_products=[];
    //   this.type=this.id;

    //   this.loading = true;
     
    //   this.axios
    //   .get(
    //     this.$store.state.base_api_url + this.$store.storeName + "/theme8/products/section/"+this.type+"/category/0",
        
    //     {
    //       headers: {
    //         "Accept-Language": this.$i18n.locale,
    //         Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName),
    //       }
    //     }
        
    //   )
    //   .then((response) => {
    //     this.new_products = response.data.data;
    //   })
    //   .then(() => (this.loading = false));
  },
  methods: {
  
   
    updatedata()
    {
      this.new_products=[];
      this.type=this.id;

      this.loading = true;
     
      this.axios
      .get(
        this.$store.state.base_api_url + this.$store.storeName + "/theme8/products/section/"+this.type+"/category/0",
        
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
            Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName),
          }
        }
        
      )
      .then((response) => {
        this.new_products = response.data.data;
      })
      .then(() => (this.loading = false));
      
      this.$store.state.langchange=false;
    }
  },
  watch:{
    langChange() {
    // this.new_products=[];
    //   this.type=this.id;

    //   this.loading = true;
     
    //   this.axios
    //   .get(
    //     this.$store.state.base_api_url + this.$store.storeName + "/theme8/products/section/"+this.type+"/category/0",
        
    //     {
    //       headers: {
    //         "Accept-Language": this.$i18n.locale,
    //         Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName),
    //       }
    //     }
        
    //   )
    //   .then((response) => {
    //     this.new_products = response.data.data;
    //   })
    //   .then(() => (this.loading = false));
  },
 
},


}
</script>