<template>
    <div class="group" :id="'fav'+id">
                        <div class="relative 
                        overflow-hidden 
                        shadow dark:shadow-gray-800 
                        group-hover:shadow-lg 
                        group-hover:dark:shadow-gray-800
                         rounded-md
                          duration-500">
                            <img 
                            :src="image" 
                            :alt="name"
                             class="product_list_img 
                             group-hover:scale-110
                              duration-500"
                             >
    
                            <div class="absolute
                             -bottom-20
                              group-hover:bottom-3
                               start-3
                                end-3 
                                duration-500">
                                 <router-link
                                 :to="{
                                 name: 'product',
                                 params: {
                                 storeName: $store.storeName,
                                  id: productid  ,
                                   name:name,
                                   lang: $store.state.routeLang
                                            },
                                   }"
                                
                                class="py-2
                                 px-5 
                                 inline-block 
                                 font-semibold
                                  tracking-wide
                                   align-middle
                                    duration-500 
                                    text-base
                                     text-center 
                                     bg-slate-900
                                      text-white
                                       w-full 
                                       rounded-md
                                       ">{{ $t('Add to Cart') }}</router-link>
                            </div>
    
                            <ul class="list-none absolute top-[10px] end-4 opacity-0 group-hover:opacity-100 duration-500 space-y-1">
                                <li>
                                <a href="javascript:void(0)" 
                                @click="changeFav"
                                class="size-10 inline-flex items-center
                                 justify-center tracking-wide 
                                 align-middle duration-500 text-center
                                  rounded-full bg-white text-slate-900 
                                  hover:bg-slate-900 hover:text-white 
                                  shadow">
                                 
                                  <i v-if="this.fav" class="mdi mdi-heart " style="color:#e22020;font-size: 24px;"
                                   ></i>
                                   <i v-else v-html="heart"></i>

                                   </a></li>
                                <li class="mt-1">
                                    <router-link   
                                 :to="{
                                 name: 'product',
                                 params: {
                                 storeName: $store.storeName,
                                  id: productid  ,
                                   name:name,
                                   lang: $store.state.routeLang
                                            },
                                   }"
                                    class=" size-10 inline-flex 
                                    items-center justify-center 
                                    tracking-wide align-middle 
                                    duration-500 text-center 
                                    rounded-full bg-white 
                                    text-slate-900 hover:bg-slate-900
                                     hover:text-white shadow">
                                    <i v-html="eye" class="size-3">
                                        </i></router-link></li>
                            </ul>
                        </div>

                        <div class="mt-4">
                            <router-link
                            :to="{
                                 name: 'product',
                                 params: {
                                 storeName: $store.storeName,
                                  id: productid  ,
                                   name:name,
                                   lang: $store.state.routeLang
                                            },
                                   }"
                                 
                                    class="hover:text-orange-500 text-lg font-medium">
                                    {{ name.substr(0,25) }}</router-link>
                            <div class="flex justify-between items-center mt-1">
                                
                                <p v-if="is_option">{{$t('PriceOnRequest')}}</p>
                                <p v-else-if="(new_price>0)&&(!is_option)">{{ new_price.toFixed(2) }}{{ unit }}
                                    <del class="text-slate-400">{{ old_price.toFixed(2) }}{{ unit }}</del></p>
                                
                                <p v-else-if="!is_option">{{ old_price.toFixed(2) }} {{ unit }}</p>

                                <ul class="font-medium text-amber-400 list-none">
                                    <li v-for="n in rate" :key="n" class="inline">
                                        <i class="mdi mdi-star"></i></li>

                                        <li v-for="n in 5 - rate" :key="n" class="inline">
                                            <i class="mdi mdi-star-outline"></i></li>
                                   
                                </ul>
                            </div>
                        </div>
                    </div><!--end content-->
</template>

<script>
import feather from 'feather-icons';
import  'feather-icons'

export default {
  components: {},
  props: {
    qnt: Number,
    min_qnt: Number,
    max_qnt: Number,
    name: String,
    description: String,
    is_favorit: Boolean,
    is_option: Boolean,
    image: String,
    old_price: Number,
    new_price: Number,
    productid: Number,
    count_view: Number,
    unit: String,
    rate: Number,
    is_offer: Boolean,
    discount: Number,
    new:Boolean,
  
    fetuered:Boolean
    

  },
  data: function () {
    return {
      fav: false,
      loginMassage: null,
      show: false,
      validateMessage: '',
      finalOptions:0,
    };
  },
  computed: {
    heart: function () {
      return feather.toSvg('heart')
    },
    eye: function () {
      return feather.toSvg('eye')
    },
  },
  created() {
    this.fav = this.is_favorit;
  },
  methods: {
    changeFav() {
      this.axios
        .get(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/favourite_product_change/" +
            this.productid,
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName),
            },
          }
        )
        .then((response) => {
          if (response.data.code == "1") {
            this.fav = !this.fav;
            if(this.fav==false)
            {document.getElementById('fav'+this.id).style='display:none'}
            else
            {document.getElementById('fav'+this.id).style='display:block'}
          }
        });
    },
   
    ////{ path: `/user/${id}` }
    productRoute() {
      if (this.$route.path.includes("/product")) {
        this.$router.push({
          name: "product",
          params: { id: this.productid, name: this.name,lang: this.$i18n.locale },
        });
      } else {
        this.$router.push({
          name: "product",
          params: {
            storeName: this.$store.storeName,
            id: this.productid,
            name: this.name,
            lang: this.$i18n.locale
          },
        });
      }
    },

    addToCart() {
console.log('%%%%%');
var checked_true = [];

this.axios
  .post(
    this.$store.state.base_api_url +
      this.$store.storeName +
      "/check_cart_item",
    {
      product_id: this.productid,
      total_price: this.old_price,
      offer_price: this.new_price,
      quantity: 1,
      old_qnt:0,
      final_option: 0,
      option_check: []
    },
    {
      headers: {
        "Accept-Language": this.$i18n.locale
      }
    }
  )
  .then(response => {
    let product = null;
    this.validateMessage = response.data.message;
    setTimeout(() => {
      // this.updateall();
      this.validateMessage = "";
    }, 3000);

    if (response.data.code == "1") {
      // let products = new Map;
      let S_name1 =
        this.$store.storeName +
        "/" +
        this.productid +
        '0' +
        checked_true;
      if (this.$cookies.isKey(S_name1)) {
      
        product = {
          product_id: this.productid,
          total_price:
            this.price + this.$cookies.get(S_name1).total_price,
          offer_price:
            this.offer_price + this.$cookies.get(S_name1).offer_price,
          quantity: Number(1) + Number(this.$cookies.get(S_name1).quantity),
          quantityAll: this.$cookies.get(S_name1).quantityAll,
          max_quantity: this.$cookies.get(S_name1).max_quantity,
          min_quantity: this.$cookies.get(S_name1).min_quantity,
          final_option: 0,
          option_check: checked_true,
          discount: this.DBitems.product.discount,
          note:this.$cookies.get(S_name1).note
        };
      } else {
        console.log("hlooooooooooo ::::::::; " + this.qnt);
        product = {
          product_id: this.productid,
          total_price: this.old_price,
          offer_price: this.new_price,
          quantity: Number(1),
          quantityAll: this.qnt,
          min_quantity: this.min_qnt,
          max_quantity: this.max_qnt,
          final_option: 0,
          option_check: checked_true,
          discount: this.discount,
          note: ''
        };
      }
      //check if item is exist on cart or not
      //if (this.$cookies.isKey(this.$store.storeName+this.$route.params.id)) {
      // this.$cookies.remove('storeName')
      // products = this.$cookies.get(this.$store.storeName);
      //}
      //products.set(this.$route.params.id , product);
      let S_name = this.$store.storeName + "/";

      if (
        !this.$cookies.isKey(
          S_name +
            product.product_id +
            product.final_option +
            product.option_check
        )
      ) {
        this.$store.state.cartCounter = this.$store.state.cartCounter + 1;
      }

      this.$cookies.set(
        S_name +
          product.product_id +
          product.final_option +
          product.option_check,
        product
      );
    }
    /*this.$router.push({
      name: "shoppingCart",
      params: {
        storeName: this.$store.storeName,
      },
    });*/

    this.$store.state.update_cart_list=true;

    setTimeout(() => {
      // this.updateall();
      this.validateMessage = "";
    }, 3000);
  });
},
  },
};
</script>
<style scoped>
.product_list_img
{
min-width: 262 px;
min-height: 262 px;
aspect-ratio:1/1;
object-fit: cover;
}
</style>