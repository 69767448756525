<template>
    <div class="sections_page" style="margin-top: 10%;">
      <div v-if="this.$store.state.langchange"></div>
      <h1 style="text-align: center; padding-bottom: 2%;" class="page_title">{{ $t("blogs") }}</h1>

        
      <section  v-if="!loading">
        <div class="container">
          <div class="row">
            <div class="col-lg-4 col-6"
             v-for="(item, index) in this.DBitems">
         <blog_card  
            :key="index"
            :postid= item.id
            :name= item.name
            :description= item.description
            :date= item.date
            :category= item.category
            :category_id= item.category_id
            :image= item.image
            >
          </blog_card>
        </div>
          </div>
        </div>

        <b-pagination
                v-model="page"
                :total-rows="count"
                :per-page="pageSize"
                :prev-text="$t('prev')"
                :next-text="$t('next')"
                pills
                size="sm"
                class="pagination mt-3 justify-content-center pagination_style1"
                @change="handlePageChange"
              ></b-pagination>
      </section>
      </div>
  </template>
  <script>
  import blog_card from "../components/one_post_list.vue";
  import sidebar from "../components/sidebar_blogs.vue";


  export default {
    components: {blog_card,sidebar},
    data: function() {
      return {
        loading: false,
        DBitems: null,
        page: 1,
        count: 0,
        pageSize: 12,
        langChange:"",
         paginate:12,
         langChange:"",
      };
    },
    created() {
      this.loading = true;
      this.axios
        .get(this.$store.state.base_api_url + this.$store.storeName+"/posts/categories/0", {
          headers: {
            "Accept-Language": this.$i18n.locale
          }
        })
        .then(response => {
          this.DBitems = response.data.data;
          this.count = response.data.meta.total;
          
          if(this.DBitems.seo_description)
        document.getElementById("description").content=
        this.DBitems.seo_description;
        if(this.DBitems.seo_keyword)
        document.getElementById("keyword_meta").content=this.DBitems.seo_keyword;
        if(this.DBitems.seo_title)
        document.title = this.DBitems.seo_title;
       
  
  
        })
        .then(() => (this.loading = false));
    },
    methods: {
      updatedata() {
        this.loading = true;
      this.axios
        .get(this.$store.state.base_api_url + this.$store.storeName+"/posts/categories/0", {
          headers: {
            "Accept-Language": this.$i18n.locale
          }
        })
        .then(response => {
          this.DBitems = response.data.data;
          if(this.DBitems.seo_description)
        document.getElementById("description").content=
        this.DBitems.seo_description;
        if(this.DBitems.seo_keyword)
        document.getElementById("keyword_meta").content=this.DBitems.seo_keyword;
        if(this.DBitems.seo_title)
        document.title = this.DBitems.seo_title;
       
  
  
        })
        .then(() => (this.loading = false));
      }
       ,
       handlePageChange(value) {
      this.page = value;
      this.retrieveTutorials();
    },

    retrieveTutorials()
    {
      this.loading = true;
      this.axios
        .get(this.$store.state.base_api_url + this.$store.storeName+"/posts/categories/0?page=" +
            this.page, {
          headers: {
            "Accept-Language": this.$i18n.locale
          }
        })
        .then(response => {
          this.DBitems = response.data.data;
               })
        .then(() => (this.loading = false));
    }

    },
    watch: {
      langChange() {
        this.loading = true;
      this.axios
        .get(this.$store.state.base_api_url + this.$store.storeName+"/posts/categories/0", {
          headers: {
            "Accept-Language": this.$i18n.locale
          }
        })
        .then(response => {
          this.DBitems = response.data.data;
          this.count = response.data.meta.total;
          
          if(this.DBitems.seo_description)
        document.getElementById("description").content=
        this.DBitems.seo_description;
        if(this.DBitems.seo_keyword)
        document.getElementById("keyword_meta").content=this.DBitems.seo_keyword;
        if(this.DBitems.seo_title)
        document.title = this.DBitems.seo_title;
       
  
  
        })
        .then(() => (this.loading = false));
      }
    },
    updated() {
        this.langChange=this.$i18n.locale
        
      },

  };
  </script>

  