<template>

    <section style="margin-top: 10%;">
            <div class="container">
          <h2 style="text-align: center; padding-bottom: 2%;" class="page_title">{{ $t("fetured products") }}</h2>
          <div
            v-if="loading"
            class="d-flex flex-row justify-content-center"
            style="margin-top: 100px; margin-bottom: 200px"
          >
            <hollow-dots-spinner
              :animation-duration="1000"
              :dot-size="35"
              :dots-num="3"
              color="var(--primary-color)"
            />
          </div> 
                <div v-else-if="this.DBitems.length!=0" class="row">
                    <div v-for="(item, idx) in this.DBitems" :key="idx" 
                class="col-lg-3 col-md-3 col-6" >
                    <product
                    :key="index" 
              :name="item.name"
              :description="item.description"
              :is_favorit="item.is_favorit"
              :image="item.image"
              :old_price="item.old_price"
              :new_price="item.new_price"
              :productid="item.id"
              :rate="item.rate"
              :count_view="item.count_views"
              :is_option="item.is_option"
              :is_offer="item.is_offer"
               :unit="item.unit"
               :new="item.is_new"
               :fetuered="item.is_fetured"
               :discount="item.discount"
               :qnt="item.qnt"
               :min_qnt="item.min_qnt"
               :max_qnt="item.max_qnt"
               :note="item.note" 
               :range_price="item.range_price"
                    >
                    </product>
                </div>
            </div>
            <div  v-else class="empty text-center center-text ">
                 
                 <img src="/assets/images/empty.png"/>
               
                 <br>
                 
                 {{ $t('empty') }}
                 </div>
            <div v-if="this.DBitems.length!=0" class="d-flex flex-row justify-content-center mt-5">
              <button v-if="page<lastPage" class="butt_white center_align" style="margin-top: 4%;" id="news_more"
            @click="loadMore()"> {{ $t('view more') }}
          </button>
            </div>
        </div>
        </section>
        
       
    </template>
<script>
import product from "../components/one_product_list.vue";
import { HalfCircleSpinner, HollowDotsSpinner } from "epic-spinners";
export default {
  components: { product, HollowDotsSpinner, HalfCircleSpinner },
  data() {
    return {
      DBitems: null,
      loading: false,

      page: 1,
      count: 0,
      pageSize: 12,
      langChange:"",
      lastPage:0
    };
  },
  created() {
    if(this.$route.params.lang&&this.$route.params.lang!=this.$i18n.locale){
      this.$i18n.locale=this.$route.params.lang;this.$store.state.UnitPrice=this.$store.state.unitAR
      if(this.$i18n.locale=='en')
        {document.documentElement.setAttribute("dir", "ltr");
        let h = document.querySelector('body');
      }
    }
    this.loading = true;
    this.axios
      .get(
        this.$store.state.base_api_url + this.$store.storeName + "/products/all_feutered",
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
            Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName),
          },
        }
      )
      .then((response) => {
        this.DBitems = response.data.data.products;
        this.count = response.data.meta.total;
        this.pageSize=response.data.meta.per_page;
        this.lastPage=response.data.meta.last_page;
      })
      .then(() => (this.loading = false));


      if(this.$i18n.locale=='ar')
      {
      document.title = this.$store.state.title_ar;
      document.getElementById("description_meta").content=this.$store.state.description_ar;
      document.getElementById("keywords_meta").content=this.$store.state.keyword_ar;
      }
      else{
        document.title = this.$store.state.title_en;
      document.getElementById("description_meta").content=this.$store.state.description_en;
      document.getElementById("keywords_meta").content=this.$store.state.keyword_en;
      }
  },


  watch: {
    langChange() {
      this.loading = true;
      this.axios
        .get(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/products/all_feutered?page=" +
            this.page,
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName),
            },
          }
        )
        .then((response) => {
          this.DBitems = response.data.data.products;
          //this.count = response.data.meta.total;
          this.pageSize=response.data.meta.per_page;
        }).then(() => {
        this.loading = false;
      });
    },
  },
  updated() {
    this.langChange=this.$i18n.locale
  },
  methods: {
    retrieveTutorials() {
      const params = this.page;

      this.loading = true;
      this.axios
        .get(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/products/all_feutered?page=" +
            params,
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName),
            },
          }
        )
        .then((response) => {
          this.DBitems = response.data.data.products;
          //this.count = response.data.meta.total;
        })
        .then(() => (this.loading = false));
      /*.catch((e) => {
          console.log(params);
          console.log(e);
        });*/
    },
    handlePageChange(value) {
      this.page = value;
      this.retrieveTutorials();
    },
    loadMore(){
      this.page++
const params = this.page;
this.axios
  .get(
    this.$store.state.base_api_url +
    this.$store.storeName +
    "/products/all_feutered?page=" +
    params,
    {
      headers: {
        "Accept-Language": this.$i18n.locale,
        Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName),
      },
    }
  )
  .then((response) => {
   
    this.DBitems.push(...response.data.data.products);
  })
    }
  },
};
</script>
