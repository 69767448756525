var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card post_card"},[_c('img',{staticClass:"one_product_image post_image",attrs:{"src":_vm.image,"alt":_vm.name,"loading":"lazy"},on:{"click":function($event){return _vm.$router.push({
               name: 'blog',
                params: {
                  storeName: _vm.$store.storeName ,
                  lang:_vm.$i18n.locale,
                  id: _vm.postid ,
                  name: _vm.name,
                  }
              });}}}),_c('div',{staticClass:"post_meta_content"},[_c('div',{staticClass:"d-flex justify-content-between desc_card"},[_c('h6',{staticClass:"post_category",on:{"click":function($event){return _vm.$router.push({
               name: 'category_blogs',
                params: {
                  storeName: _vm.$store.storeName ,
                  lang:_vm.$i18n.locale,
                  id: _vm.category_id ,
                  name: _vm.category,
                  }
              });}}},[_vm._v(_vm._s(this.category))]),_c('p',[_vm._v(" "+_vm._s(this.date)+" ")])]),_c('div',{},[_c('h6',{staticClass:"title_card",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$router.push({
               name: 'blog',
                params: {
                  storeName: _vm.$store.storeName ,
                  lang:_vm.$i18n.locale,
                  id: _vm.postid ,
                  name: _vm.name,
                  }
              });}}},[_vm._v(_vm._s(_vm.name))]),_c('router-link',{staticClass:"post_link",attrs:{"to":{
          name: 'blog',
          params: {
             storeName: this.$store.storeName,
             lang:_vm.$store.state.routeLang ,
            id: _vm.postid ,
            name: _vm.name,},
        }}},[_vm._v(_vm._s(_vm.$t("read more"))+"...")])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }