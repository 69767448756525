import { render, staticRenderFns } from "./ViewAddress.vue?vue&type=template&id=55e555ee&scoped=true&"
import script from "./ViewAddress.vue?vue&type=script&lang=js&"
export * from "./ViewAddress.vue?vue&type=script&lang=js&"
import style0 from "./ViewAddress.vue?vue&type=style&index=0&id=55e555ee&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55e555ee",
  null
  
)

export default component.exports