<template>
    <div>
         <!-- Start Hero -->
         <section class="page_title relative table w-full py-20 lg:py-24 bg-gray-50 dark:bg-slate-800">
            <div class="container relative">
                <div class="grid grid-cols-1 mt-14">
                    <h3 class="text-3xl leading-normal font-semibold">{{$t('Shopcart')}}</h3>
                </div><!--end grid-->

                <div class="relative mt-3">
                    <ul class="tracking-[0.5px] mb-0 inline-block">
                        <li class="inline-block uppercase
                         text-[13px] font-bold 
                         duration-500 ease-in-out 
                         hover:text-orange-500">
                         <router-link to="/home">{{$t('home')}}</router-link>
                        </li>
                        <li class="inline-block text-base
                         text-slate-950 dark:text-white mx-0.5 
                         ltr:rotate-0 rtl:rotate-180">
                         <i class="mdi mdi-chevron-right"></i></li>
                        <li class="inline-block uppercase text-[13px] 
                        font-bold text-orange-500" aria-current="page">{{$t('Shopcart')}}</li>
                    </ul>
                </div>
            </div><!--end container-->
        </section><!--end section-->
        <!-- End Hero -->

        <!-- Start -->
        <section class="relative md:py-24 py-16">
            <div class="container relative">
              <div>
                <a v-if="this.items.length != 0" 
               class="btn  btn-sm clear-cart"
                style="cursor:pointer;
               color:rgb(214, 21, 21);
                 font-size:16px;

                     "
  @click="show = true">
    <i class="mdi mdi-eraser"></i>
         
         {{ $t("Emptycart") }}
      
     </a>    
     <div
          v-if="this.$localStorage.get(this.$store.storeName) == null && this.items.length != 0"
          class="login_alert_cart"
        >( {{ $t("loginMessage1") }}  <router-link  class=""
          style="color:#9c0008 ; text-decoration: 2px underline !important;
           text-underline-offset: 5px; font-weight: bold;"
          :to="{
            name: 'login',
            params: { storeName: this.$store.storeName,lang:$store.state.routeLang },
          }">{{ $t("loginMessage2") }}</router-link>  {{ $t("loginMessage3") }} )
          </div>
  
                </div>

                <div class="grid lg:grid-cols-1">
                    <div class="relative overflow-x-auto shadow dark:shadow-gray-800 rounded-md">
                     
                        <table v-if="this.items.length != 0" class="w-full text-start">
                            <thead class="text-sm uppercase bg-slate-50 dark:bg-slate-800">
                                <tr>
                                    <th scope="col" class="p-4 w-4"></th>
                                    <th scope="col" class="text-start p-4 min-w-[220px]">{{$t('product')}}</th>
                                    <th scope="col" class="p-4 w-24 min-w-[100px]">{{$t('price')}}</th>
                                    <th scope="col" class="p-4 w-56 min-w-[220px]">{{$t('Qty')}}</th>
                                    <th scope="col" class="p-4 w-24 min-w-[100px]">{{$t('total')}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <cart_item v-for="item in this.items" :key="item" 
                                :item-id="item"
                                v-if="item"
                                 >
                                </cart_item>
                            </tbody>
                        </table>
                        <div v-else>
 <div class="container">
          <h4
            style="text-align: center; text-decoration: 2px underline; text-underline-offset: 3px; margin-top:1%;"
          >{{ $t('cartEmpty')}} !!</h4>
          <a
      style="text-align: center; text-decoration: 2px underline; text-underline-offset: 3px; 
      margin-top:1%;
      cursor:pointer;
      "
        
        @click="$router.push({
              name: 'home',
              params: { storeName: $store.storeName,lang:$store.state.routeLang },
            })
            "
      >{{ $t("Continueshopping") }}</a>
    
      <img
        v-if="this.items.length == 0"
        style="width: 50%; margin-left: 25%; margin-right: 25%;    
         filter: grayscale(0.85);"
        src="https://etrolley.net/store/assets/image/emptyCard.gif"
      />

      
       </div>
                          </div>
                  
                    </div>

                    <div class="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 mt-6 gap-6">
                        <div class="lg:col-span-9 md:order-1 order-3">
                            <div class="space-x-1">
                                <router-link 
                                :to="{
                                   name: 'home1',
                                params: { storeName: this.$store.storeName,lang:$store.state.routeLang },
                                          }"
                                class="cart_btn py-2 px-5
                                 inline-block
                                  font-semibold
                                   tracking-wide
                                    align-middle
                                     text-base 
                                     text-center 
                                     bg-orange-500
                                      text-white 
                                      rounded-md mt-2">{{ $t('shop Now') }}</router-link>
                                <router-link
                                v-if="this.$localStorage.get(this.$store.storeName)&&(this.items.length != 0)"
               
                                     :to="{
                                   name: 'checkout',
                                params: { storeName: this.$store.storeName,lang:$store.state.routeLang },
                                          }"
                                 class="
                                 cart_btn
                                 py-2 px-5 
                                 inline-block font-semibold 
                                 tracking-wide align-middle 
                                 text-base text-center rounded-md
                                  bg-orange-500/5 hover:bg-orange-500 
                                  text-orange-500 hover:text-white mt-2">{{ $t('confirmOrder') }}
                                </router-link>

                                <router-link
                                v-else-if="(this.items.length != 0)"
               
                                     :to="{
                                   name: 'login',
                                params: { storeName: this.$store.storeName,lang:$store.state.routeLang },
                                          }"
                                 class="cart_btn py-2 px-5 
                                 inline-block font-semibold 
                                 tracking-wide align-middle 
                                 text-base text-center rounded-md
                                  bg-orange-500/5 hover:bg-orange-500 
                                  text-orange-500 hover:text-white mt-2">{{ $t('logIn') }}</router-link>
                            </div>
                        </div>

                        <div class="lg:col-span-3 md:order-2 order-1">
                            <ul class="list-none shadow dark:shadow-gray-800 rounded-md">
                                <li class="flex justify-between p-4">
                                    <span class="font-semibold text-lg">{{$t('Subtotal')}} :</span>
                                    <span class="text-slate-400">{{ (price*this.$store.state.rate).toFixed(2) }} {{ this.$store.state.UnitPrice }}</span>
                                </li>
                                <li 
                                v-if="$store.state.tax!=0&&$store.state.tax != null"
                                class="flex justify-between p-4 border-t border-gray-100 dark:border-gray-800">
                                    <span class="font-semibold text-lg"> {{ $t("tax") }} ( {{ $store.state.tax }}% ): :</span>
                                    <span class="text-slate-400">{{ ((price*this.$store.state).toFixed(2)* $store.state.tax/100).toFixed(2)}}  {{ this.$store.state.UnitPrice }}
                                        
                                    </span>
                                </li>
                                <li class="flex justify-between font-semibold p-4 border-t border-gray-200 dark:border-gray-600">
                                    <span class="font-semibold text-lg">{{ $t('total') }} :</span>
                                    <span class="font-semibold">{{
                
                
                                        (this.price +
                                         ($store.state.tax * this.price*this.$store.state.rate) / 100 
            
                                         ).toFixed(2)
                                     }} {{ this.$store.state.UnitePrice }}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div><!--end container-->

           
        </section><!--end section-->
        <!-- End -->
        <Transition name="modal">
      <div v-if="show" class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <div class="modal-header">
              <slot name="header">{{$t('confirmdeleteAll')}}</slot>
            </div>
            <div class="modal-footer">
              <slot name="footer">
                <button class="modal-default-button" @click="deleteAllCookies() ">{{$t('confirm')}}</button>
                <button
                  class="modal-default-button cancel"
                  @click="(show = false), $emit('close')"
                >{{$t('cancel')}}</button>
              </slot>
            </div>
          </div>
        </div>
      </div>
    </Transition>

        </div>
    </template>

    
<script>
import cart_item from "../components/cart_item_shoopping.vue";
export default {
  components: { cart_item},
  data: function() {
    return {
      unit: this.$store.state.unit,
      langChange:'',
      items: [],
      products: [],
      loading: false,
      show: false,
      price: 0,
    };
  },
  watch: {
    langChange() {
      
console.log('///////////11111111111')
      this.loading=true;
      if(this.$i18n.locale == 'ar')
    {
        this.unit=this.$store.state.unitAR;
    }
    let AllCookies = this.$cookies.keys();
    this.products = [];
    this.items = [];
    this.price = 0;

    for (let i = 0; i < AllCookies.length; i++) {
      if (AllCookies[i].split("/")[0] == this.$store.storeName) {
        this.items.push(AllCookies[i]);
        this.products.push(this.$cookies.get(AllCookies[i]));

        if (this.$cookies.get(AllCookies[i]).offer_price != 0) {
          this.price += this.$cookies.get(AllCookies[i]).offer_price;
        } else {
          this.price += this.$cookies.get(AllCookies[i]).total_price;
        }
        //console.log(this.items);
      }
    }
    console.log(this.items);

    
        this.loading=false;
  }
  },
  updated() {
    this.langChange=this.$i18n.locale
    console.log('/*******************/',this.langChange)
    
  },
  created() {
    this.$store.state.topbar='topbar_light';
   this.$store.state.navbar='navbar_dark';

    this.loading=true;
    if(this.$i18n.locale == 'ar')
    {
        this.unit=this.$store.state.unitAR;
    }
    let AllCookies = this.$cookies.keys();
    this.products = [];
    this.price = 0;

    for (let i = 0; i < AllCookies.length; i++) {
      if (AllCookies[i].split("/")[0] == this.$store.storeName) {
        this.items.push(AllCookies[i]);
        this.products.push(this.$cookies.get(AllCookies[i]));

        if (this.$cookies.get(AllCookies[i]).offer_price != 0) {
          this.price += this.$cookies.get(AllCookies[i]).offer_price;
        } else {
          this.price += this.$cookies.get(AllCookies[i]).total_price;
        }
        //console.log(this.items);
      }
    }
    console.log(this.items);

    //console.log(this.items[0])
    /* this.$cookies.isKey(
          this.$store.storeName +
            product.product_id +
            product.final_option +
            product.option_check
        )*/

        this.loading=false;

        if(this.$i18n.locale=='ar')
      {
      document.title = this.$store.state.title_ar;
      document.getElementById("description_meta").content=this.$store.state.description_ar;
      document.getElementById("keywords_meta").content=this.$store.state.keyword_ar;
      }
      else{
        document.title = this.$store.state.title_en;
      document.getElementById("description_meta").content=this.$store.state.description_en;
      document.getElementById("keywords_meta").content=this.$store.state.keyword_en;
      }
  },
  methods: {
    updateProducts() {
      // console.log("hiiiiiiiiiiiiiii");
      this.items = [];
      this.products = [];
      this.price = 0;

      let AllCookies = this.$cookies.keys();
      for (let i = 0; i < AllCookies.length; i++) {
        if (AllCookies[i].split("/")[0] == this.$store.storeName) {
          this.items.push(AllCookies[i]);
          this.products.push(this.$cookies.get(AllCookies[i]));

          if (this.$cookies.get(AllCookies[i]).offer_price != 0) {
          this.price += this.$cookies.get(AllCookies[i]).offer_price;
        } else {
          this.price += this.$cookies.get(AllCookies[i]).total_price;
        }
          //console.log(this.items);
        }
      }
    },
    updatedata() {

      this.loading = true;
      this.items = [];
      this.products = [];
      this.price = 0;
      
      let AllCookies = this.$cookies.keys();
      for (let i = 0; i < AllCookies.length; i++) {
        if (AllCookies[i].split("/")[0] == this.$store.storeName) {
          this.items.push(AllCookies[i]);
          this.products.push(this.$cookies.get(AllCookies[i]));

          if (this.$cookies.get(AllCookies[i]).offer_price != 0) {
          this.price += this.$cookies.get(AllCookies[i]).offer_price;
        } else {
          this.price += this.$cookies.get(AllCookies[i]).total_price;
        }
          console.log(this.items);
        }
      }
      this.$store.state.langchange = false;
      setTimeout(() => {
        // this.updateall();
        this.loading = false;
      }, 0.1);
    },
    deleteAllCookies() {
      this.loading = true;
      for (let i = 0; i < this.items.length; i++) {
        this.$cookies.remove(this.items[i]);
      }
      this.items = [];
      this.price=0;

      console.log("hiiiiiiii" + this.$cookies.keys());
      this.$store.state.cartCounter = 0;
      this.loading = false;
      this.show = false;
    },
    confirmOrder() {
      this.axios
        .post(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/check_all_item_card",
          {
            products: this.products
          },
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName)
            }
          }
        )
        .then(response => {
          if (response.data.code == "1") {
            this.$router.push({
              name: "confirmOrder",
              params: { storeName: this.$store.storeName,lang: this.$i18n.locale }
            });
          } else {
            alert(response.data.message);
            // this.couponMsg = response.data.message;
          }
          //this.addresses = response.data.data.addresses.addresses;
          // console.log(this.addresses);
        })
        .catch(error => {
          if (error.response.status == "401") {
            this.$localStorage.remove(this.$store.storeName);
                     window.location.href="/";

          }
        });
    }
  }
};
</script>