<template>
    <div >
      <div
         v-if="loadingSlider==true"
        class="d-flex flex-row justify-content-center loading_card"
       
      >
        <!-- LOADER -->
        <div  class="loading_card_spinner">
  <hollow-dots-spinner
          :animation-duration="1000"
          :dot-size="35"
          :dots-num="3"
          color="var(--primary-color)"
        />
     
</div>
</div>
      <carousel
              v-if="sliders.length !=0&&loadingSlider==false"
                :per-page="1"
                :mouse-drag="false"
                autoplay
                loop
                :autoplayTimeout=5000
                :autoplayDirection="forward"
                :paginationEnabled="false"
              >
                <slide v-for="(item, idx) in sliders" :key="idx"> 
                <!-- Start Hero -->
     <section  
     class="banner_section" 
     v-bind:style="'background-image:url('+item.image+');'"
     >
            <div class="absolute inset-0 bg-gradient-to-t to-transparent
             via-slate-900/50 from-slate-900/90"></div>
            <div class="container relative">
                <div class="grid grid-cols-1 justify-center" style="padding:10% 5%">
                    <div class="text-center">
                        <span class="uppercase font-semibold text-lg text-white">
                            {{ item.sub_title }}
                        </span>
                        <h4 style="min-height:200px"class="md:text-6xl text-4xl md:leading-normal leading-normal font-bold text-white my-3">{{ item.title }}</h4>
                        <p v-html="item.description" class="text-lg text-white/70"></p>

                        <div class="mt-6">
                            <a  v-if="item.value" 
                            :href="item.value"  
                            class="py-2 px-5 inline-block font-semibold tracking-wide align-middle text-center bg-white title rounded-md">
                                {{ $t('Shop Now') }} <i class="mdi mdi-arrow-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </section>  
                  <!-- end Hero -->
                
              </slide>
              </carousel>
              </div>
     
</template>

<script>
 import VueSlickCarousel from 'vue-slick-carousel'
 import Slick from 'vue-slick';

    import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import { Carousel, Slide } from "vue-carousel";
import { HalfCircleSpinner, HollowDotsSpinner,CirclesToRhombusesSpinner } from "epic-spinners";

export default {
 
  name: "Banners",
  components: {
    Slick,
    VueSlickCarousel,
    Carousel,
    Slide,
    HalfCircleSpinner,
     HollowDotsSpinner,
     CirclesToRhombusesSpinner
   
  },
  props: {
   
  },
  data: function () {

    return {
        amount: {
        langChange: true,
        name: this.$i18n.t('key_name'),
        value: this.$i18n.t('key_value')
      },
      slickOptions: {
        "slidesToScroll": 1,
  "autoplay": true,
  "autoplaySpeed": 4000,
  "pauseOnDotsHover": true,
  "pauseOnFocus": true,
  "pauseOnHover": true,
  "touchThreshold": 5,
          autoplay: true,
          slidesToShow: 1,
          speed:200,
          "arrows": true,
          dots: false,
          "centerMode": true,
          "infinite": true,
          "fade":true,
          "useCSS":true,
          "waitForAnimate"	:true
                // Any other options that can be got from plugin documentation
            },
    sliders:[],
    array_slide:[],
    loadingSlider :true,
    items:[1,2],
    settings: {
  "slidesToScroll": 1,
  "autoplay": true,
  "autoplaySpeed": 4500,
  "pauseOnDotsHover": true,
  "pauseOnFocus": true,
  "pauseOnHover": true,
  "touchThreshold": 5,
          "autoplay": true,
          slidesToShow: 1,
          speed:200,
          "arrows": true,
          dots: false,
          "centerMode": true,
          "infinite": true,
          "fade":true,
          "useCSS":true,
          "waitForAnimate"	:true
        },
    };
  },
  created() {
    this.loadingSlider = true;
    this.axios
      .get(
        this.$store.state.base_api_url + this.$store.storeName + "/sliders",
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
          },
        }
      )
      .then((response) => {
        this.sliders = response.data.data.sliders;


      })
      .then(() => {
        this.loadingSlider = false;
        console.log('sliders section',this.sliders)

      });
      console.log('sliders section',this.sliders)

  },

 // All slick methods can be used too, example here
// All slick methods can be used too, example here
methods: {
        next() {
            this.$refs.slick.next();
        },

        prev() {
            this.$refs.slick.prev();
        },

        reInit() {
            // Helpful if you have to deal with v-for to update dynamic lists
            this.$nextTick(() => {
                this.$refs.slick.reSlick();
            });
        },

        // Events listeners
        handleAfterChange(event, slick, currentSlide) {
            console.log('handleAfterChange', event, slick, currentSlide);
        },
        handleBeforeChange(event, slick, currentSlide, nextSlide) {
        
        var i=0;
        for(i=0;i<this.sliders.length;i++)
        {
             var element=document.getElementById('slide'+i);
             if(element)
             if(element.classList.contains( "active" ))
             {
              element.classList.remove("active");
             }
        }
        //
        var element=document.getElementById('slide'+nextSlide);
        if(element)
        element.classList.add( "active" );
        
          },
        handleBreakpoint(event, slick, breakpoint) {
            console.log('handleBreakpoint', event, slick, breakpoint);
        },
        handleDestroy(event, slick) {
            console.log('handleDestroy', event, slick);
        },
        handleEdge(event, slick, direction) {
            console.log('handleEdge', event, slick, direction);
        },
        handleInit(event, slick) {
            console.log('handleInit', event, slick);
        },
        handleReInit(event, slick) {
            console.log('handleReInit', event, slick);
        },
        handleSetPosition(event, slick) {
            console.log('handleSetPosition', event, slick);
        },
        handleSwipe(event, slick, direction) {
            console.log('handleSwipe', event, slick, direction);
        },
        handleLazyLoaded(event, slick, image, imageSource) {
            console.log('handleLazyLoaded', event, slick, image, imageSource);
        },
        handleLazyLoadError(event, slick, image, imageSource) {
            console.log('handleLazeLoadError', event, slick, image, imageSource);
        },
        updatedata() 
        {
            this.loadingSlider = true;
    this.axios
      .get(
        this.$store.state.base_api_url + this.$store.storeName + "/sliders",
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
          },
        }
      )
      .then((response) => {
        this.sliders = response.data.data.sliders;


      })
      .then(() => {
        this.loadingSlider = false;
        console.log('sliders section',this.sliders)

      });
      console.log('sliders section',this.sliders)
        },
      

        
    },
    watch: {
    langChange() {
      this.loadingSlider = true;
    this.axios
      .get(
        this.$store.state.base_api_url + this.$store.storeName + "/sliders",
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
          },
        }
      )
      .then((response) => {
        this.sliders = response.data.data.sliders;


      })
      .then(() => {
        this.loadingSlider = false;
        console.log('sliders section',this.sliders)

      });
      console.log('sliders section',this.sliders)
    },
  },
  updated() {
    //this.langChange=this.$i18n.locale
  },



};
</script> 