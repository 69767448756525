<template>
    <section class="single_blog ">
      <div v-if="this.$store.state.langchange"></div>
      <div class="title center-text text-center">
        <h1 style="text-align: center; padding-bottom: 2%;" class="page_title">{{DBitems.name }}</h1>
    
               <img :src=DBitems.image 
                   style="width:100%;
                     max-width:400px;
                    object-fit:cover;
                    border-radius: 6%;
                    height:400px;
                    background:lightgray;"
                    class="one_image_product"/>
      
      <div class="meta_single_blog">
     <label class=" meta_blog category"> <i class="bi bi-bookmarks-fill"></i> {{ this.DBitems.category }}</label>
     <label class="meta_blog date"><i class="bi bi-calendar"></i> {{ this.DBitems.date }}</label>
     <label class="meta_blog view"><i class="bi bi-eye"></i> {{ this.DBitems.view }}</label>
  
      </div>
      </div>
     
  
        <!--<div class="container" v-html="DBitems.title"></div>-->
        <div v-if="this.DBitems" class="container" v-html="this.DBitems.description">
          <!--v-html=""-->
        </div>


        <div v-if=" this.posts.length>0" class="container">
          <h3 style=" padding-bottom: 2%;" class="page_title">{{  $t("related blogs") }}</h3>

          <div class="row">
            <div class="col-lg-3 col-6"
             v-for="(item, index) in this.posts">
         <blog_card  
            :key="index"
            :postid= item.id
            :name= item.name
            :description= item.description
            :date= item.date
            :category= item.category
            :category_id= item.category_id
            :image= item.image
            >
          </blog_card>
        </div>
    </div>
        </div>
     
    </section>
  </template>
  <script>
    import blog_card from "../components/one_post_list.vue";

  export default {

    components: {blog_card},
    data: function() {
      return {
        loading: false,
        DBitems: null,
        posts:[],
        langChange:''
      };
    },
    created() {
  
      this.loading = true;
      this.axios
        .get(this.$store.state.base_api_url + this.$store.storeName+"/post/"+this.$route.params.id, {
          headers: {
            "Accept-Language": this.$i18n.locale
          }
        })
        .then(response => {
          this.DBitems = response.data.data.post;
          this.posts = response.data.data.posts;
          console.log("2024-03-11 posys",this.posts)
          if (this.DBitems.seo_title)
          document.title = this.DBitems.seo_title;
        else
          document.title = this.DBitems.name;
        if (this.DBitems.seo_description)
          document.getElementById("description_meta").content = this.DBitems.seo_description;
        if (this.DBitems.seo_keyword)
          document.getElementById("keywords_meta").content = this.DBitems.seo_keyword;
  
        })
        .then(() => (this.loading = false));
  
    },
    methods: {
      updatedata() {
      this.loading = true;
      this.axios
        .get(this.$store.state.base_api_url + this.$store.storeName+"/post/"+this.$route.params.id, {
          headers: {
            "Accept-Language": this.$i18n.locale
          }
        })
        .then(response => {
          this.DBitems = response.data.data.post;
          this.posts = response.data.data.posts;

          console.log("gggg  "+this.DBitems);
          
          if(this.DBitems.seo_description)
          document.getElementById("description").content=
          this.DBitems.seo_description;
          if(this.DBitems.seo_keyword)
          document.getElementById("keyword_meta").content=this.DBitems.seo_keyword;
          if(this.DBitems.seo_title)
          document.title = this.DBitems.seo_title;
  
        })
        .then(() => (this.loading = false));
  
    }
    },
    watch: {
    "$route.params": {
      handler(newValue) {
        this.updatedata();
      },
      immediate: true
    },
    langChange() {updatedata()}
  },
  updated() {
        this.langChange=this.$i18n.locale
      },
  
  };
  </script>
  
  <style scoped>
  .single_blog {
    padding-top: 10%;
    min-height: 600px;
  }
  .blog_img
  {
    aspect-ratio: 1;
    object-fit: cover;
    max-height:400px;
  }
  @media (max-width: 992px) {
    .single_blog {
    padding-top: 10%;
    min-height: 600px;
  }
  }

  .meta_blog
  {
    margin:2% 5%;
    background: var(--primary-color);
      color: white;
      padding: 4px 8px;
      border-radius: 12px;
      text-align: center;
      min-width:150px
  }
  </style>
  