<template>
  <footer class="footer bg-dark-footer relative text-gray-200 dark:text-gray-200">
    <div class="container relative">
      <div class="grid grid-cols-12">
        <div class="col-span-12">
          <div class="py-[60px] px-0">
            <div class="grid md:grid-cols-12 grid-cols-1 gap-6">
              <div class="lg:col-span-3 md:col-span-12" style="max-width:150px">
                <a href="#" class="text-[22px] focus:outline-none">
                  <img :alt="this.$store.state.storeName" :src="DBitems.market.logo_footer" style="width:100%"
                    width="150">
                </a>
                <p class="mt-6 text-gray-300">

                  {{ DBitems.market.name }}
                  {{ DBitems.market.description }}
                </p>
                <p class="mt-6 text-gray-300">
                  {{ DBitems.market.subdescription }}
                </p>

                <ul class="list-none mt-6">
                  <li class="inline" v-if="DBitems.social.facebook">
                    <a :href="DBitems.social.facebook" target="_blank" class="size-8 inline-flex
                                               items-center justify-center
                                                tracking-wide align-middle text-base
                                                 border border-gray-800 dark:border-slate-800
                                                  rounded-md hover:text-orange-500 dark:hover
                                                  :text-orange-500 text-slate-300">
                      <i v-html="facebook" class="h-3 w-3 align-middle" title="facebook"></i>
                    </a>
                  </li>


                  <li class="inline" v-if="DBitems.social.instgram">
                    <a :href="DBitems.social.instgram" target="_blank" class="size-8
                                             inline-flex items-center
                                              justify-center 
                                              tracking-wide align-middle
                                               text-base border border-gray-800 
                                               dark:border-slate-800 rounded-md 
                                               hover:text-orange-500 dark:hover:text-orange-500 
                                               text-slate-300">
                      <i v-html="instagram" class="h-3 w-3
                                                align-middle" title="instagram"></i></a>
                  </li>

                  <li class="inline" v-if="DBitems.social.instagram">
                    <a :href="DBitems.social.instgram" target="_blank" class="size-8 inline-flex 
                                            items-center justify-center tracking-wide
                                             align-middle text-base border border-gray-800 
                                             dark:border-slate-800 rounded-md hover:text-orange-500
                                              dark:hover:text-orange-500 text-slate-300">
                      <i v-html="twitter" class="h-3 w-3 align-middle" title="twitter"></i>
                    </a>
                  </li>

                  <li class="inline" v-if="DBitems.social.whatsapp">
                    <a :href="DBitems.social.whatsapp" class="size-8 inline-flex items-center
                                             justify-center tracking-wide align-middle
                                              text-base border border-gray-800 dark:border-slate-800
                                               rounded-md hover:text-orange-500 
                                               dark:hover:text-orange-500 text-slate-300">
                      <i class="mdi mdi-whatsapp
                                               h-3 w-3 align-middle icon_social_footer" title="whatsapp"></i></a>
                  </li>


                  <li class="inline" v-if="DBitems.social.tiktok">
                    <a :href="DBitems.social.tiktok" class="size-8 inline-flex items-center
                                             justify-center tracking-wide align-middle
                                              text-base border border-gray-800 dark:border-slate-800
                                               rounded-md hover:text-orange-500 
                                               dark:hover:text-orange-500 text-slate-300">

                      <i class="
                                               h-3 w-3 align-middle icon_social_footer" title="whatsapp">
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 50 50" fill="none"
                          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                          class="feather">
                          <path
                            d="M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z" />

                        </svg>
                      </i>


                    </a>
                  </li>


                  <li class="inline" v-if="DBitems.social.snapchat">
                    <a :href="DBitems.social.snapchat" class="size-8 inline-flex items-center
                                             justify-center tracking-wide align-middle
                                              text-base border border-gray-800 dark:border-slate-800
                                               rounded-md hover:text-orange-500 
                                               dark:hover:text-orange-500 text-slate-300">
                      <i class="mdi mdi-snapchat h-3 w-3 align-middle icon_social_footer" title="snapchat"></i></a>
                  </li>
                </ul><!--end icon-->
              </div><!--end col-->

              <div class="lg:col-span-6 md:col-span-12">
                <h5 class="tracking-[1px] text-gray-100 font-semibold">
                </h5>

                <div class="grid md:grid-cols-12 grid-cols-1">
                  <div class="md:col-span-4">
                    <h5 class="tracking-[1px] text-gray-100 font-semibold">{{ $t('sections') }}</h5>

                    <ul class="list-none footer-list mt-6">
                      <li v-for="(item, index) in this.DBitems.sections" :key="index" v-if="index <= i" class="mt-[10px]">
                        <router-link :to="{
                          name: 'section',
                          params: { storeName: $store.storeName, id: item.id, name: item.label, lang: $store.state.routeLang },
                        }" class="text-gray-300 hover:text-gray-400 duration-500 ease-in-out">
                          <i class="mdi mdi-chevron-right"></i>
                          {{ item.label }} </router-link>
                      </li>
                    </ul>
                  </div><!--end col-->

                  <div class="md:col-span-4">
                    <h5 class="tracking-[1px] text-gray-100 font-semibold" style="color:transparent !important">{{
                      $t('Quick Links') }}</h5>

                    <ul class="list-none footer-list mt-6">
                      <li v-for="(item, index) in this.DBitems.sections" :key="index" v-if="index > i" class="mt-[10px]">
                        <router-link :to="{
                          name: 'section',
                          params: { storeName: $store.storeName, id: item.id, name: item.label, lang: $store.state.routeLang },
                        }" class="text-gray-300 hover:text-gray-400 duration-500 ease-in-out">
                          <i class="mdi mdi-chevron-right"></i>
                          {{ item.label }} </router-link>
                      </li>
                    </ul>
                  </div><!--end col-->

                  <div class="md:col-span-4">
                    <h5 class="tracking-[1px] text-gray-100 font-semibold">{{ $t('Quick Links') }}</h5>

                    <ul class="list-none footer-list mt-6">
                      <li><router-link :to="{
                        name: 'home',
                        params: { storeName: this.$store.storeName, lang: $store.state.routeLang },
                      }"
                          class="text-gray-300 hover:text-gray-400 duration-500 ease-in-out">
                          <i class="mdi mdi-chevron-right"></i> {{ $t('home') }} </router-link></li>
                      <li class="mt-[10px]">
                        <router-link :to="{
                          name: 'about',
                          params: { storeName: this.$store.storeName, lang: $store.state.routeLang },
                        }" class="text-gray-300 hover:text-gray-400 duration-500 ease-in-out">
                          <i class="mdi mdi-chevron-right"></i>
                          {{ $t('about') }} </router-link>
                      </li>
                      <li class="mt-[10px]">
                        <router-link :to="{
                          name: 'privacy',
                          params: { storeName: this.$store.storeName, lang: $store.state.routeLang },
                        }" class="text-gray-300 hover:text-gray-400 duration-500 ease-in-out">
                          <i class="mdi mdi-chevron-right"></i> {{ $t('privacyPolicy') }} </router-link>
                      </li>
                      <li class="mt-[10px]">
                        <router-link :to="{
                          name: 'brands',
                          params: { storeName: this.$store.storeName, lang: $store.state.routeLang },
                        }" class="text-gray-300 hover:text-gray-400 
                                                    duration-500 ease-in-out">
                          <i class="mdi mdi-chevron-right"></i>
                          {{ $t('brands') }}</router-link>
                      </li>

                    </ul>
                  </div>
                  <!--end col-->
                </div>
              </div>

              <div class="lg:col-span-3 md:col-span-2">
                <h5 class="tracking-[1px] text-gray-100 font-semibold">
                  {{ $t('Download Links') }}</h5>

                <div class="my-5">
                  <a :href="DBitems.social.ios_link"><img src="theme8/images/app.png" class="m-1 inline-block"
                      :alt="DBitems.market.name"></a>
                </div>
                <div class="my-5">
                  <a :href="DBitems.social.andrioad_link">
                    <img src="theme8/images/playstore.png" class="m-1 inline-block" :alt="DBitems.market.name"></a>
                </div>

              </div><!--end col-->
            </div><!--end grid-->
          </div><!--end col-->
        </div>
      </div><!--end grid-->

      <div class="grid grid-cols-1">
        <div class="py-[30px] px-0 border-t border-slate-800">
          <div class="grid lg:grid-cols-4 md:grid-cols-2">
            <div class="flex items-center lg:justify-center">
              <i class="mdi mdi-truck-check-outline align-middle text-lg mb-0 me-2"></i>
              <h6 class="mb-0 font-medium">{{ $t('Free delivery') }}</h6>
            </div><!--end content-->

            <div class="flex items-center lg:justify-center">
              <i class="mdi mdi-archive align-middle text-lg mb-0 me-2"></i>
              <h6 class="mb-0 font-medium">{{ $t('Non-contact shipping') }}</h6>
            </div><!--end content-->

            <div class="flex items-center lg:justify-center">
              <i class="mdi mdi-cash-multiple align-middle text-lg mb-0 me-2"></i>
              <h6 class="mb-0 font-medium">{{ $t('Money-back quarantee') }}</h6>
            </div><!--end content-->

            <div class="flex items-center lg:justify-center">
              <i class="mdi mdi-shield-check align-middle text-lg mb-0 me-2"></i>
              <h6 class="mb-0 font-medium">{{ $t('Secure payments') }}</h6>
            </div><!--end content-->
          </div><!--end grid-->
        </div><!--end-->
      </div><!--end grid-->
    </div><!--end container-->

    <div dir="ltr" class="py-[30px] px-0 border-t border-slate-800">
      <div class="container relative text-center">
        <div class="grid md:grid-cols-2 items-center">
          <div class="md:text-start text-center">
            <p v-if="DBitems.market.copy_right" class="mb-0">
              {{ DBitems.market.copy_right }}

              <a href="https://etrolley.net" target="_blank" class="text-reset  link_etrolley">
                {{ $t('Powered By Etrolley') }}</a>.
            </p>

            <p v-else class="mb-0">
              {{ $t("all rights are save") }}
              {{ this.DBitems.market.name }}


              <a href="https://etrolley.net" target="_blank" class="text-reset link_etrolley">

                {{ $t('Powered By Etrolley') }}</a>.
            </p>
          </div>

          <ul class="list-none md:text-end text-center mt-6 md:mt-0">
            <li class="inline"><a href=""><img src="theme8/images/ab1.jpg" class="max-h-6 rounded inline"
                  title="American Express" alt=""></a></li>
            <li class="inline"><a href=""><img src="theme8/images/payments/discover.jpg" class="max-h-6 rounded inline"
                  title="Discover" alt=""></a></li>
            <li class="inline"><a href=""><img src="theme8/images/payments/mastercard.jpg"
                  class="max-h-6 rounded inline" title="Master Card" alt=""></a></li>
            <li class="inline"><a href=""><img src="theme8/images/payments/paypal.jpg" class="max-h-6 rounded inline"
                  title="Paypal" alt=""></a></li>
            <li class="inline"><a href=""><img src="theme8/images/payments/visa.jpg" class="max-h-6 rounded inline"
                  title="Visa" alt=""></a></li>
          </ul>
        </div><!--end grid-->
      </div><!--end container-->
    </div>
  </footer><!--end footer-->
</template>
<script>
export default {

  name: "Footer",
  props: {

  },
  data: function () {
    return {
      i: 3,
      logo: "",
      DBitems: null,
      loading: false,
      langChange: this.$i18n.locale
    };
  },
  computed: {
    dribbble: function () {
      return feather.toSvg('dribbble')
    },
    linkedin: function () {
      return feather.toSvg('linkedin')
    },
    facebook: function () {
      return feather.toSvg('facebook')
    },
    instagram: function () {
      return feather.toSvg('instagram')
    },
    twitter: function () {
      return feather.toSvg('twitter')
    },
    whatsapp: function () {
      return feather.toSvg('phone')
    },
    tiktok: function () {
      return feather.toSvg('tiktok')
    },
    snapchat: function () {
      return feather.toSvg('snapchat')
    },
  },
  created() {
    this.loading = true;
    this.$store.storeName = this.$store.state.storeName;
    console.log(this.$store.storeName);
    this.axios
      .get(this.$store.state.base_api_url + this.$store.storeName + "/footer_theme8",
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
          },
        })
      .then((response) => (this.DBitems = response.data.data
        //  this.i=response.data.data.sections/2
      ))
      .then(() => (this.loading = false));

  },
  methods: {
    updatedata() {
      this.loading = true;
      this.$store.storeName = this.$store.state.storeName;
      console.log(this.$store.storeName);
      this.axios
        .get(this.$store.state.base_api_url + this.$store.storeName + "/footer_theme8",
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
            },
          })
        .then((response) => (this.DBitems = response.data.data
          //this.i=response.data.data.sections/2
        ))
        .then(() => (this.loading = false));

    }
  },
  updated() {
    this.langChange = this.$i18n.locale
  },
  watch: {
    langChange() {
      this.loading = true;
      this.$store.storeName = this.$store.state.storeName;
      console.log(this.$store.storeName);
      this.axios
        .get(this.$store.state.base_api_url + this.$store.storeName + "/footer_theme8",
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
            },
          })
        .then((response) => (this.DBitems = response.data.data
        ))
        .then(() => (this.loading = false));

    },
    langChange1() {
      this.loading = true;
      this.$store.storeName = this.$store.state.storeName;
      console.log(this.$store.storeName);
      this.axios
        .get(this.$store.state.base_api_url + this.$store.storeName + "/footer_theme8",
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
            },
          })
        .then((response) => (this.DBitems = response.data.data
        ))
        .then(() => (this.loading = false));

    },
  },

};
</script>