<template>
  <div style="min-height:800px !important">
    <div v-if="loading" class="d-flex flex-row justify-content-center loading_card">
      <!-- LOADER -->
      <div class="loading_card_spinner">
        <hollow-dots-spinner :animation-duration="1000" :dot-size="35" :dots-num="3" color="var(--primary-color)" />

      </div>
      <!-- END LOADER -->

    </div>
    <div v-else>
      <div v-if="this.$store.state.langchange">{{ updatedata() }}</div>
      <section id="section_product" style="direction: ltr;">
        <div class="slideshow-container product_images">
          <!-- <a @click="changeFav" id="myBtn" class="fav_icon_product">
            <i v-if="this.fav" id="faPlus" class="fa fa-heart font-xs" style="color:red"></i>
            <i v-else id="faPlus" class=" fa fa-heart-o font-xs" style="color:black"></i>
          </a> -->
          <carousel 
               
                :per-page="1"
                :mouse-drag="false"
                autoplay
                loop
                :paginationEnabled="false"
                >
            <slide v-for="(item, idx) in this.DBitems.images.images" :key="idx">
              <div dir="ltr">
                <img :src=item.src style="width:100%;
                    object-fit:cover;
                    border-radius: 6%;
                    height:400px;
                    background:lightgray;"
                   
                      class="one_image_product">
                <div class="top-right2">

                </div>
              </div>
            </slide>
          </carousel>
        </div>
        <br>

        <div style="text-align:center">
          <span v-for="(item, index) in this.DBitems.images.images" class="dot"></span>
        </div>
      </section>

      <section>
        <div class="container">
          <div class=" d-flex justify-content-between">
            <div style=" display: inline-block;vertical-align: middle;padding-left: 1%;">
              <h5 class="product_name">{{ this.DBitems.product.name }}</h5>
              <span>{{ $t('category') }}: {{ this.DBitems.product.category }}</span>
            </div>
            <div v-if="selectOption" style="display: inline-block;" class="green_div"> {{ $t('selectOption') }}
            </div>

            <div v-else-if="offer_price == 0" style="display: inline-block;" class="green_div">
              {{ this.price.toFixed($store.state.digit_price) }} {{ this.DBitems.product.unit }}
            </div>

            <div v-else style="display: inline-block;" class="green_div">
              {{ this.offer_price.toFixed($store.state.digit_price) }} {{ this.DBitems.product.unit }}
              <del class="text-red-600">
                {{ this.price.toFixed($store.state.digit_price) }} {{ this.DBitems.product.unit }}</del>
            </div>

          </div>

          <div style="margin-top: 2%;">
            <a style="cursor:pointer"
              :href="'https://api.whatsapp.com/send/?phone=' + this.$store.state.phone + '&text=' + this.DBitems.product.msg + '&type=phone_number&app_absent=0'"
              target="_blank">
              <img src="https://nabat.online/images/whatsapp.png" style="width: 50px; ">
              <div style=" display: inline-block;vertical-align: middle;padding-left: 1%;">
                <h6 class="color_font">{{ $t('Inquire about the product') }}</h6>
              </div>
            </a>

          </div>

          <div v-if="this.DBitems.product.description" style="margin-top: 1%;">
            <h5>{{ $t('Description') }}</h5>
            <p style="color: #606060; font-size: 17px;
                 text-align: justify;" v-html="this.DBitems.product.description"></p>
          </div>

          <div v-for="(item, index) in DBitems.options.options" :key="index">
            <div v-if="item.type == 'select'" class="orderInfo d-flex flex-row justify-content-start"
              style="margin-bottom: 15px">
              <h5 class="product_option_name">{{ item.name }} :</h5>
              <select class="select select_product" :title="$t('SelectOne')" @change="upadateprice($event, item.id)">
                <option hidden selected>{{ $t("SelectOne") }}</option>
                <option :value="item1.id" v-for="(item1, index) in item.option_values.select_values" :key="index">{{
                  item1.name }}</option>
              </select>
            </div>
            <div v-if="item.type == 'checkbox'">
              <div v-for="(item1, index1) in checked" :key="index1">
                <div class="orderInfo d-flex flex-row justify-content-start" style="margin-bottom: 15px"
                  v-if="checked[index1].id == item.id">
                  <h5 class="product_option_name">{{ item.name }} :</h5>
                  <div class="d-flex flex-row justify-content-between" style="width: 120px">
                    <label v-if="$i18n.locale == 'ar'" for="checkbox">{{ item.price }} {{ $store.state.unitAR }}</label>
                    <label v-else for="checkbox">{{ item.price }} {{ $store.state.unit }}</label>
                    <input type="checkbox" id="checkbox" v-model="checked[index1].value"
                      style="width: 30px; height: 25px" @change="changeCheckbox(item, checked[index1].value)" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="d-flex justify-content-between justify-content-lg-end  justify-content-xl-end
            center_align">
            <div class="qnt_product"  v-if="(quanity == 0 || max_quanity == 0) && !selectOption">{{ $t("NoQuantity") }}</div>
            <div class="qnt_product" v-else-if="selectOption">{{ $t("selectOption_qut") }}</div>

            <div class="qnt_product" v-else>
              <div
                style="background-color: #ffff; color: var(--primary-color); border: 1px solid var(--primary-color); padding: 9px 18px;"
                class="value-button" id="decrease" @click="decrementClick()" value="Decrease Value">-</div>
              <input type="number" id="number" :value="counterVal" />
              <div class="value-button" id="increase" @click="incrementClick()" value="Increase Value">+</div>
            </div>

            <button class="butt_green product_butt"  @click="addToCart" id="#addToCart">
              {{ $t('Add To Cart') }}</button>
          </div>
        </div>
      </section>
      <div v-if="validateMessage != ''" style="
            position: fixed;
            z-index: 100;
            width: 300px;
            bottom: 50px;
            left: 50px;
            border-radius: 5px;
            background-color: var(--primary-color);
            border: 2px solid var(--primary-color);
            text-align: center;
            padding-top: 5px;
            color:white;
          ">
        <p>{{ validateMessage }}</p>
      </div>

      <section>
        <div class="container" v-if="this.DBitems.similar_products.products.length != 0">
          <h4 style="margin-top: 2%;">{{ $t("similarProducts") }}</h4>

          <div class="row">
            <div class="col-lg-3 col-md-3 col-6" v-for="(item, index) in this.DBitems.similar_products.products"
              :key="index">
              <product :id="item.id" :name="item.name" :description="item.description" :is_favorit="item.is_favorit"
                :image="item.image" :old_price="item.old_price" :new_price="item.new_price" :productid="item.id"
                :rate="item.rate" :count_view="item.count_views" :is_option="item.is_option" :is_offer="item.is_offer"
                :unit="item.unit" :new="item.is_new" :fetuered="item.is_fetured" :discount="item.discount"
                :qnt="item.qnt" :min_qnt="item.min_qnt" :max_qnt="item.max_qnt">
              </product>

            </div>

          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import product from "../components/one_product_list.vue";


import Vodal from "vodal";
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import { Carousel, Slide } from "vue-carousel";
import "vodal/common.css";
import "vodal/slide-down.css";
import "vodal/flip.css";
import { HalfCircleSpinner, HollowDotsSpinner } from "epic-spinners";

export default {
  components: {
    Vodal,
    product,
    Carousel,
    Slide,
    HalfCircleSpinner,
    HollowDotsSpinner
  },

  data: function () {
    return {
      msg: "",
      counterVal: 1,
      loginVodal: false,
      loginMassage: "",
      old_qnt: 0,
      currentId: null,
      width: 600,
      height: 350,
      images: null,
      count_images: null,
      DBitems: null,
      loading: true,
      options: [],
      selectedOptionArray: [],
      price: 0,
      quanity: 0,
      max_quanity: 0,
      min_quanity: 0,
      Firstquanity: 0,
      finalOptions: 0,
      checked: [],
      validateMessage: "",
      selectOption: false,
      fav: false,
      offer_price: 0,
      note: "",
      addeval: false
    };
  },
  created() {
    if (this.$route.params.lang && this.$route.params.lang != this.$i18n.locale) {
      this.$i18n.locale = this.$route.params.lang; this.$store.state.UnitPrice = this.$store.state.unitAR
      if (this.$i18n.locale == 'en') {
        document.documentElement.setAttribute("dir", "ltr");
        let h = document.querySelector('body');
      }
    }
            
    this.loading = true;
    this.axios
      .get(
        this.$store.state.base_api_url +
        this.$store.storeName +
        "/product/" +
        this.$route.params.id,
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
            Authorization:
              "Bearer " + this.$localStorage.get(this.$store.storeName)
          }
        }
      )
      .then(response => {
        this.loading = false;
        this.checked = [];
        this.DBitems = response.data.data;
        this.images = response.data.data.images.images;
        document.querySelectorAll('meta[property=og\\:image]')[0].setAttribute('content', this.images[0].src)

        this.count_images = response.data.data.images.images.length;
        this.fav = this.DBitems.product.is_favorit;
        if (this.DBitems.product.seo_title)
          document.title = this.DBitems.product.seo_title;
        else
          document.title = this.DBitems.product.name;
        if (this.DBitems.product.seo_description)
          document.getElementById("description_meta").content = this.DBitems.product.seo_description;
        if (this.DBitems.product.seo_keyword)
          document.getElementById("keywords_meta").content = this.DBitems.product.seo_keyword;
        var option1 = this.DBitems.options.options;
        for (var i = 0; i < option1.length; i++) {
          //if option  not empty
          if (option1[i].option_values.select_values != null) {
            // if select
            var element = {};
            element.value_id = option1[i].option_values.select_values[0].id;
            element.option_id = option1[i].id;
            this.options.push(element);
            ///console.log(this.options.length +"iam theeeeeeeeeeeeeeer")
          } else {
            // if checkbox
            var checked = {};
            checked.id = option1[i].id;
            checked.value = false;
            this.checked.push(checked);
            //console.log(this.checked)
          }
        }
        //console.log(this.options)
      })
      .then(() => {
        if (this.options.length != 0) {
          this.selectOption = true;

        }
        else if (this.options.length == 0) {
          // if we not found select options
          //console.log("hiiiiiiiiii created" + this.DBitems.product.quanity);
          this.price = this.DBitems.product.sale_price;
          this.offer_price = this.DBitems.product.offer_price;
          this.quanity = this.DBitems.product.quantity;
          this.max_quanity = this.DBitems.product.quantity;
          this.min_quanity = 1;
          if (this.DBitems.product.min_qnt)
            this.min_quanity = this.DBitems.product.min_qnt;
          if (this.DBitems.product.max_qnt > 0)
            this.max_quanity = this.DBitems.product.max_qnt;
          this.Firstquanity = this.DBitems.product.quantity;

          /////////////nottttttttttttttttttteeeeeeeeeeeeeeeeeeee////////////
          var checked_true = [];
          for (var i = 0; i < this.checked.length; i++) {
            if (this.checked[i].value == true) {
              checked_true.push(this.checked[i].id);
            }
          }
          let S_name_full =
            this.$store.storeName +
            "/" +
            this.$route.params.id +
            this.finalOptions +
            checked_true;

          let S_name2 =
            this.$store.storeName +
            "/" +
            this.$route.params.id +
            this.finalOptions;
          console.log("S_name " + S_name2);

          let qun = this.quanity;
          let min_qun = this.min_quanity;
          let max_qun = this.max_quanity;
          let AllCookies = this.$cookies.keys();
          for (let i = 0; i < AllCookies.length; i++) {
            if (AllCookies[i].includes(S_name2)) {

              this.old_qnt = this.$cookies.get(AllCookies[i]).quantity;
              qun = qun - this.$cookies.get(AllCookies[i]).quantity;
              min_qun = min_qun - this.$cookies.get(AllCookies[i]).quantity;
              if (max_qun != -1)
                max_qun = max_qun - this.$cookies.get(AllCookies[i]).quantity;
              if (max_qun == -1) {
                max_qun = -1;
              }
              else if (max_qun <= 0 && max_qun != -1) {
                max_qun = 0;
              }
              console.log(
                this.$cookies.get(AllCookies[i]).quantity + " quuuuunb" + qun
              );
              // }
            }
          }
          this.quanity = qun;
          this.min_quanity = min_qun;
          this.max_quanity = max_qun;

        }
        if (this.min_quanity > 0) {
          this.counterVal = this.min_quanity;
          this.price *= this.counterVal;
          this.offer_price *= this.counterVal;
        }
      });

    //counter value 


    //end counter value
  },
  methods: {
    incrementClick() {


      if (this.quanity > 0) {
        console.log("*************** max qnt   " + this.max_quanity + " ");
        if (this.counterVal < this.quanity && this.counterVal < this.max_quanity) {
          ++this.counterVal;
          this.price = this.price / (this.counterVal - 1) + this.price;
          if (this.offer_price > 0) {
            this.offer_price =
              this.offer_price / (this.counterVal - 1) + this.offer_price;
            /*   this.offer_price =
              this.price - (this.DBitems.product.discount / 100) * this.price;*/
          }
        }
      } else {
        ++this.counterVal;
        this.price = this.price / (this.counterVal - 1) + this.price;
        if (this.offer_price > 0) {
          this.offer_price =
            this.offer_price / (this.counterVal - 1) + this.offer_price;
          /* this.offer_price =
            this.price - (this.DBitems.product.discount / 100) * this.price;*/
        }
      }
      //}
    },
    decrementClick() {
      if (this.counterVal > 1 && this.counterVal > this.min_quanity) {
        --this.counterVal;
        //this.price = this.price/2
        this.price = this.price - this.price / (this.counterVal + 1);
        if (this.offer_price > 0) {
          this.offer_price =
            this.offer_price - this.offer_price / (this.counterVal + 1);
          /*    this.offer_price =
            this.price - (this.DBitems.product.discount / 100) * this.price;*/
        }
      }
    },
    updatedata() {
      this.loading = true;
      this.axios
        .get(
          this.$store.state.base_api_url +
          this.$store.storeName +
          "/product/" +
          this.$route.params.id,
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization:
                "Bearer " + this.$localStorage.get(this.$store.storeName)
            }
          }
        )
        .then(response => {
          this.DBitems = response.data.data;
          // this.counterVal = 1;
          //   if(this.DBitems.min_quanity>1)
          //   this.counterVal = this.DBitems.min_quanity;
          if (this.DBitems.product.seo_title)
            document.title = this.DBitems.product.seo_title;
          else
            document.title = this.DBitems.product.name;
          if (this.DBitems.product.seo_description)
            document.getElementById("description_meta").content = this.DBitems.product.seo_description;
          if (this.DBitems.product.seo_keyword)
            document.getElementById("keywords_meta").content = this.DBitems.product.seo_keyword;
          this.images = response.data.data.images.images;

          this.fav = this.DBitems.product.is_favorit;
        })
        .then(() => (this.loading = false));

      //this.$refs.childref.update(this.categoryID)
      this.$store.state.langchange = false;
    },
    upadateprice(event, option_id) {
      for (var i = 0; i < this.options.length; i++) {
        if (this.options[i].option_id == option_id) {
          this.options[i].value_id = event.target.value;
          if (this.selectedOptionArray.length < this.options.length) {
            //if (!this.selectedOptionArray.includes(this.options[i].option_id)) {
            this.selectedOptionArray.push(this.options[i].option_id);
            //}
          }
        }
      }
      //console.log(this.options.length  +" hii " +this.selectedOptionArray.length)
      if (this.options.length == this.selectedOptionArray.length) {
        this.selectOption = false;

        var options = this.options;
        this.axios
          .post(
            this.$store.state.base_api_url +
            this.$store.storeName +
            "/product_price/" +
            this.$route.params.id,
            {
              options
            },
            {
              headers: {
                "Accept-Language": this.$i18n.locale
              }
            }
          )
          .then(response => {
            this.price = response.data.data.price;
            this.offer_price = response.data.data.new_price;
            this.quanity = response.data.data.quanity;
            this.max_quanity = response.data.data.quanity;
            this.min_quanity = 1;
            if (response.data.data.min_qnt > 1)
              this.min_quanity = response.data.data.min_qnt;
            if (response.data.data.max_qnt > 0)
              this.max_quanity = response.data.data.max_qnt;
            this.Firstquanity = response.data.data.quanity;
            this.finalOptions = response.data.data.final_option;
            for (var i = 0; i < this.checked.length; i++) {
              this.checked[i].value = false;
            }


            if (response.data.data.image) {

              if (this.images.length > this.count_images)
                this.images.shift();
              this.images.unshift(

                {
                  id: '1',
                  src: response.data.data.image,
                  thumbnail: response.data.data.image
                }

              )
            }
            this.note = "";
            var checked_true = [];
            for (var i = 0; i < this.checked.length; i++) {
              if (this.checked[i].value == true) {
                checked_true.push(this.checked[i].id);
              }
            }
            let S_name_full =
              this.$store.storeName +
              "/" +
              this.$route.params.id +
              this.finalOptions +
              checked_true;

            let S_name2 =
              this.$store.storeName +
              "/" +
              this.$route.params.id +
              this.finalOptions;
            console.log("S_name " + S_name2);

            let qun = this.quanity;
            let min_qun = this.min_quanity;
            let max_qun = this.max_quanity;

            let AllCookies = this.$cookies.keys();
            for (let i = 0; i < AllCookies.length; i++) {
              if (AllCookies[i].includes(S_name2)) {

                this.old_qnt = this.$cookies.get(AllCookies[i]).quantity;
                qun = qun - this.$cookies.get(AllCookies[i]).quantity;
                min_qun = min_qun - this.$cookies.get(AllCookies[i]).quantity;
                if (min_qun < 0) {
                  min_qun = 1;
                }
                if (max_qun != -1)
                  max_qun = max_qun - this.$cookies.get(AllCookies[i]).quantity;
                if (max_qun == -1) {
                  max_qun = -1;
                }
                else if (max_qun <= 0 && max_qun != -1) {
                  max_qun = 0;
                }

                console.log(
                  this.$cookies.get(AllCookies[i]).quantity + " quuuuunx " + qun
                );
                // }
              }
            }
            this.quanity = qun;
            this.max_quanity = max_qun;
            this.min_quanity = min_qun;


            //test
            if (this.min_quanity > 0) {
              this.counterVal = this.min_quanity;
              this.price *= this.counterVal;
              this.offer_price *= this.counterVal;
            }
            else {
              this.counterVal = 1;
              this.price *= this.counterVal;
              this.offer_price *= this.counterVal;
            }


          });
      }
    },
    updateall() {
      this.loading = true;
      this.selectOption = false;
      this.counterVal = 1;
      this.options = [];
      this.selectedOptionArray = [];
      this.price = 0;
      this.offer_price = 0;
      this.quanity = 0;
      this.min_quanity = 0;
      this.max_quanity = 0;
      this.Firstquanity = 0;
      this.finalOptions = 0;
      this.checked = [];
      //this.validateMessage = "";

      this.axios
        .get(
          this.$store.state.base_api_url +
          this.$store.storeName +
          "/product/" +
          this.$route.params.id,
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization:
                "Bearer " + this.$localStorage.get(this.$store.storeName)
            }
          }
        )
        .then(response => {
          this.checked = [];
          this.DBitems = response.data.data;

          if (this.DBitems.product.seo_title)
            document.title = this.DBitems.product.seo_title;
          else
            document.title = this.DBitems.product.name;
          if (this.DBitems.product.seo_description)
            document.getElementById("description_meta").content = this.DBitems.product.seo_description;
          if (this.DBitems.product.seo_keyword)
            document.getElementById("keywords_meta").content = this.DBitems.product.seo_keyword;
          this.images = response.data.data.images.images;

          this.fav = this.DBitems.product.is_favorit;
          var option1 = this.DBitems.options.options;
          for (var i = 0; i < option1.length; i++) {
            //if option  not empty
            if (option1[i].option_values.select_values != null) {
              // if select
              var element = {};
              element.value_id = option1[i].option_values.select_values[0].id;
              element.option_id = option1[i].id;
              this.options.push(element);
            } else {
              // if checkbox
              var checked = {};
              checked.id = option1[i].id;
              checked.value = false;
              this.checked.push(checked);
              //console.log(this.checked)
            }
          }
          //console.log(this.options)
        })
        .then(() => {
          this.loading = false;
          if (this.options.length != 0) {
            this.selectOption = true;
            /*// if we found select options
            var options = this.options;
            this.axios
              .post(
                this.$store.state.base_api_url +
                  this.$store.storeName +
                  "/product_price/" +
                  this.$route.params.id,
                {
                  options,
                },
                {
                  headers: {
                    "Accept-Language": this.$i18n.locale,
                  },
                }
              )
              .then((response) => {
                this.price = response.data.data.price;
                this.offer_price = response.data.data.new_price;
                this.quanity = response.data.data.quanity;
                this.Firstquanity = response.data.data.quanity;
                this.finalOptions = response.data.data.final_option;
 
                /////////////nottttttttttttttttttteeeeeeeeeeeeeeeeeeee////////////
                var checked_true = [];
                for (var i = 0; i < this.checked.length; i++) {
                  if (this.checked[i].value == true) {
                    checked_true.push(this.checked[i].id);
                  }
                }
                let S_name_full =
                  this.$store.storeName +
                  "/" +
                  this.$route.params.id +
                  this.finalOptions +
                  checked_true;
 
                let S_name2 =
                  this.$store.storeName +
                  "/" +
                  this.$route.params.id +
                  this.finalOptions;
                console.log("S_name " + S_name2);
 
                let qun = this.quanity;
 
                let AllCookies = this.$cookies.keys();
                for (let i = 0; i < AllCookies.length; i++) {
                  if (AllCookies[i].includes(S_name2)) {
                    // if (AllCookies[i] == S_name_full) {
                    //  console.log("hii i am there ");
                    //} else {
                    qun = qun - this.$cookies.get(AllCookies[i]).quantity;
                    console.log(
                      this.$cookies.get(AllCookies[i]).quantity +
                        " quuuuun " +
                        qun
                    );
                    // }
                  }
                }
                this.quanity = qun;
              });*/
          } else if (this.options.length == 0) {
            // if we not found select options
            console.log(
              "hiiiiiiiiii updateall" + this.DBitems.product.sale_price
            );
            this.price = this.DBitems.product.sale_price;
            this.offer_price = this.DBitems.product.offer_price;
            this.min_quanity = 1;
            if (this.DBitems.product.min_qnt)
              this.min_quanity = this.DBitems.product.min_qnt;

            this.max_quanity = this.DBitems.product.quantity;
            if (this.DBitems.product.max_qnt > 0)
              this.max_quanity = this.DBitems.product.max_qnt;

            this.quanity = this.DBitems.product.quantity;
            this.Firstquanity = this.DBitems.product.quantity;

            /////////////nottttttttttttttttttteeeeeeeeeeeeeeeeeeee////////////
            var checked_true = [];
            for (var i = 0; i < this.checked.length; i++) {
              if (this.checked[i].value == true) {
                checked_true.push(this.checked[i].id);
              }
            }
            let S_name_full =
              this.$store.storeName +
              "/" +
              this.$route.params.id +
              this.finalOptions +
              checked_true;

            let S_name2 =
              this.$store.storeName +
              "/" +
              this.$route.params.id +
              this.finalOptions;
            console.log("S_name " + S_name2);

            let qun = this.quanity;
            let min_qun = this.min_quanity;
            let max_qun = this.max_quanity;

            let AllCookies = this.$cookies.keys();
            for (let i = 0; i < AllCookies.length; i++) {
              if (AllCookies[i].includes(S_name2)) {

                console.log(
                  " qnt " + qun
                  + '<br> qnt_cookies ' + this.$cookies.get(AllCookies[i]).quantity
                  + "<br> max_qnt " + max_qun
                  + "<br> max_qnt cookies " + max_qun
                );

                this.old_qnt = this.$cookies.get(AllCookies[i]).quantity;
                qun = qun - this.$cookies.get(AllCookies[i]).quantity;
                min_qun = min_qun - this.$cookies.get(AllCookies[i]).quantity;
                if (min_qun < 0) {
                  min_qun = 1;
                }
                if (max_qun != -1)
                  max_qun = max_qun - this.$cookies.get(AllCookies[i]).quantity;
                if (max_qun == -1) {
                  max_qun = -1;
                }
                else if (max_qun <= 0 && max_qun != -1) {
                  max_qun = 0;
                }
                //this.$cookies.get(AllCookies[i]).quantity;
                console.log(
                  this.$cookies.get(AllCookies[i]).quantity + " quuuuuny  " + qun
                  + "max_qnt " + max_qun
                );
                // }
              }

            }
            this.quanity = qun;
            this.min_quanity = min_qun;
            this.max_quanity = max_qun;

            if (this.min_quanity > 0) {
              this.counterVal = this.min_quanity;
              this.price *= this.counterVal;
              this.offer_price *= this.counterVal;
            }
          }
        });
    },
    addToCart() {
      document.getElementById("#addToCart").style.display = "none";
      var checked_true = [];
      for (var i = 0; i < this.checked.length; i++) {
        if (this.checked[i].value == true) {
          checked_true.push(this.checked[i].id);
        }
      }
      this.axios
        .post(
          this.$store.state.base_api_url +
          this.$store.storeName +
          "/check_cart_item",
          {
            product_id: this.$route.params.id,
            total_price: this.price,
            offer_price: this.offer_price,
            quantity: this.counterVal,
            old_qnt: this.old_qnt,
            final_option: this.finalOptions,
            option_check: checked_true
          },
          {
            headers: {
              "Accept-Language": this.$i18n.locale
            }
          }
        )
        .then(response => {
          let product = null;
          if (response.data.code == "1") {
            // let products = new Map;
            let S_name1 =
              this.$store.storeName +
              "/" +
              this.$route.params.id +
              this.finalOptions +
              checked_true;
            if (this.$cookies.isKey(S_name1)) {
              /*console.log(
                "this.price " +
                  this.price +
                  "this.$cookies.get( S_name1 ).total_price" +
                  this.$cookies.get(S_name1).total_price
              );*/
              product = {
                product_id: this.$route.params.id,
                total_price:
                  this.price + this.$cookies.get(S_name1).total_price,
                offer_price:
                  this.offer_price + this.$cookies.get(S_name1).offer_price,
                quantity: Number(this.counterVal) + Number(this.$cookies.get(S_name1).quantity),
                quantityAll: this.$cookies.get(S_name1).quantityAll,
                max_quantity: this.$cookies.get(S_name1).max_quantity,
                min_quantity: this.$cookies.get(S_name1).min_quantity,
                final_option: this.finalOptions,
                option_check: checked_true,
                discount: this.DBitems.product.discount,
                note: this.note + " " + this.$cookies.get(S_name1).note
              };
            } else {
              console.log("hlooooooooooo ::::::::; " + this.Firstquanity);
              product = {
                product_id: this.$route.params.id,
                total_price: this.price,
                offer_price: this.offer_price,
                quantity: Number(this.counterVal),
                quantityAll: this.Firstquanity,
                min_quantity: this.min_quanity,
                max_quantity: this.max_quanity,
                final_option: this.finalOptions,
                option_check: checked_true,
                discount: this.DBitems.product.discount,
                note: this.note
              };
            }
            //check if item is exist on cart or not
            //if (this.$cookies.isKey(this.$store.storeName+this.$route.params.id)) {
            // this.$cookies.remove('storeName')
            // products = this.$cookies.get(this.$store.storeName);
            //}
            //products.set(this.$route.params.id , product);
            let S_name = this.$store.storeName + "/";

            if (
              !this.$cookies.isKey(
                S_name +
                product.product_id +
                product.final_option +
                product.option_check
              )
            ) {
              this.$store.state.cartCounter = this.$store.state.cartCounter + 1;
            }

            this.$cookies.set(
              S_name +
              product.product_id +
              product.final_option +
              product.option_check,
              product
            );
            console.log(this.$cookies.keys());
          }
          this.validateMessage = response.data.message;
          /*this.$router.push({
            name: "shoppingCart",
            params: {
              storeName: this.$store.storeName,
            },
          });*/
          this.updateall();
          document.getElementById("#addToCart").style.display = "block";
          setTimeout(() => {
            // this.updateall();
            this.validateMessage = "";
          }, 3000);
        });
    },
    addEvalu() {
      if (
        this.$localStorage.get(this.$store.storeName) != null &&
        this.DBitems.product.is_rate == true
      ) {
        this.addeval = true;
      } else if (
        this.$localStorage.get(this.$store.storeName) != null &&
        this.DBitems.product.is_rate == false
      ) {
        if (this.$i18n.locale == "en") {
          document
            .getElementsByClassName("hovertext")[0]
            .setAttribute(
              "data-hover",
              "You must purchase the product to add a review"
            );
        } else {
          document
            .getElementsByClassName("hovertext")[0]
            .setAttribute("data-hover", "يجب أن تشتري المنتج لإضافة تقييم");
        }
      } else {
        if (this.$i18n.locale == "en") {
          document
            .getElementsByClassName("hovertext")[0]
            .setAttribute("data-hover", "login to add evaluation");
        } else {
          document
            .getElementsByClassName("hovertext")[0]
            .setAttribute("data-hover", "سجل دخول لإضافة تقييم");
        }
      }
    },
    changeFav() {
      if (this.$localStorage.get(this.$store.storeName) != null) {
        this.axios
          .get(
            this.$store.state.base_api_url +
            this.$store.storeName +
            "/favourite_product_change/" +
            this.$route.params.id,
            {
              headers: {
                "Accept-Language": this.$i18n.locale,
                Authorization:
                  "Bearer " + this.$localStorage.get(this.$store.storeName)
              }
            }
          )
          .then(response => {
            if (response.data.code == "1") {
              this.fav = !this.fav;
              console.log('ggggggggggggggggggggggggggggggggggggggggggg')
            }
          });
      } else {
        if (this.$i18n.locale == "en") {
          this.loginMassage = "please Login";
          setTimeout(() => {
            this.loginMassage = "";
          }, 3000);
        } else {
          this.loginMassage = "يجب تسجيل الدخول";
          setTimeout(() => {
            this.loginMassage = "";
          }, 3000);
        }
      }
    },
    changeCheckbox(item, val) {
      if (val) {
        this.price = this.price + item.price * this.counterVal;
        if (this.offer_price != 0) {
          this.offer_price = this.offer_price + item.price * this.counterVal;
        }
      } else {
        /*(this.offer_price =
            this.price - (this.DBitems.product.discount / 100) * this.price))*/
        this.price = this.price - item.price * this.counterVal;
        if (this.offer_price != 0) {
          this.offer_price = this.offer_price - item.price * this.counterVal;
        }
        /*(this.offer_price =
            this.price - (this.DBitems.product.discount / 100) * this.price));*/
      }
    }
  },
  watch: {
    "$route.params": {
      handler(newValue) {
        this.updateall();
      },
      immediate: true
    }
  }
};
</script>