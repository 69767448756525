<template>
   <div class="container relative">
    <div class="grid md:grid-cols-12 grid-cols-1 items-center">
        <div class="lg:col-span-5 md:col-span-6">
            <img :src="$store.state.logo" class="mx-auto d-block" alt="Gazali Perfuimes">
        </div>
        <div class="lg:col-span-7 md:col-span-6">
            <span class="bg-orange-500/5 text-orange-500 text-xs font-bold px-2.5 py-0.5 rounded h-5">{{$t('Mobile Apps')}}</span>
            <h4 class="font-semibold text-3xl leading-normal my-4">{{ $t('Available for your') }} 
                <br> {{ $t('Smartphones') }}</h4>
            <p class="text-slate-400 max-w-xl mb-0">{{ $t('Upgrade your style with our curated sets. Choose confidence, embrace your unique look.') }}</p>
            <div class="my-5">
                <a :href="DBitems.social.ios_link"><img src="theme8/images/app.png" 
                class="m-1 inline-block" :alt="DBitems.market.name"></a>

                <a :href="DBitems.social.andrioad_link">
                    <img src="theme8/images/playstore.png"
                     class="m-1 inline-block" :alt="DBitems.market.name"></a>
            </div>

            <div class="inline-block">
                <div class="pt-4 flex items-center border-t border-gray-100 dark:border-gray-700">
                    <i  class="me-2 title h-10 w-10">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <g fill="none" stroke="black" stroke-linecap="round"
                        stroke-linejoin="round" stroke-width="1.1"><rect width="14" 
                        height="20" x="5" y="2" rx="2" ry="2"/>
                        <path d="M12 18h.01"/></g></svg>
                    </i>
                    <div class="content">
                        <h6 class="mb-0 text-base font-medium">{{ $t('Install app now on your cellphones') }}</h6>
                        <a :href="DBitems.social.andrioad_link"
                         class="title">{{ $t('Learn More') }}
                         <i class="mdi mdi-arrow-right"></i></a> 
                    </div>
                </div>
            </div>
        </div>
    </div><!--end gird-->
</div> 
</template>
<script>
export default {
    computed: {
    smartphone: function () {
      return feather.toSvg('smartphone')
    },
   
  },
  name: "Footer",
  props: {
   
  },
  data: function () {
    return {
        i:3,
      logo: "",
      DBitems: null,
      loading: false,
    };
  },
  computed: {
   
  },
  created() {
    this.loading = true;
    this.$store.storeName = this.$store.state.storeName;
    console.log(this.$store.storeName);
    this.axios
      .get(this.$store.state.base_api_url + this.$store.storeName + "/footer_theme8",
      {
          headers: {
            "Accept-Language": this.$i18n.locale,
          },
        })
      .then((response) => (this.DBitems = response.data.data
                          //  this.i=response.data.data.sections/2
      ))
      .then(() => (this.loading = false));
      
  },
  methods: {
    updatedata() {
      this.loading = true;
    this.$store.storeName = this.$store.state.storeName;
    console.log(this.$store.storeName);
    this.axios
      .get(this.$store.state.base_api_url + this.$store.storeName + "/footer_theme8",
      {
          headers: {
            "Accept-Language": this.$i18n.locale,
          },
        })
      .then((response) => (this.DBitems = response.data.data
      //this.i=response.data.data.sections/2
      ))
      .then(() => (this.loading = false));
      
    }
  },
  watch: {
  langChange() {
    this.loading = true;
    this.$store.storeName = this.$store.state.storeName;
    console.log(this.$store.storeName);
    this.axios
      .get(this.$store.state.base_api_url + this.$store.storeName + "/footer_theme8",
      {
          headers: {
            "Accept-Language": this.$i18n.locale,
          },
        })
      .then((response) => (this.DBitems = response.data.data
      ))
      .then(() => (this.loading = false));
      
  },
  langChange1() {
    this.loading = true;
    this.$store.storeName = this.$store.state.storeName;
    console.log(this.$store.storeName);
    this.axios
      .get(this.$store.state.base_api_url + this.$store.storeName + "/footer_theme8",
      {
          headers: {
            "Accept-Language": this.$i18n.locale,
          },
        })
      .then((response) => (this.DBitems = response.data.data
      ))
      .then(() => (this.loading = false));
      
  },
},

};
</script> 