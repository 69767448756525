<template>
    <div style="min-height:600px">
      <div
         v-if="loading==true"
        class="d-flex flex-row justify-content-center loading_card"
       
      >
        <!-- LOADER -->
        <div  style="position: fixed;
    top: 40%;
    left: 40%;" >
  <hollow-dots-spinner
          :animation-duration="1000"
          :dot-size="35"
          :dots-num="3"
          color="var(--primary-color)"
        />
     
</div>
      </div>
         <!-- Google Map -->
         <div class="container-fluid relative mt-20">
            <div class="grid grid-cols-1">
                <div class="w-full leading-[0] border-0">
                    <img
                    :src="DBitems.social.image"
                    style="border:0;background-color:lightgray;"
                     class="w-full h-[500px]"
                      allowfullscreen
                      loading="lazy"
                      
                      >
                </div>
            </div><!--end grid-->
        </div><!--end container-->
        <!-- Google Map -->

        <!-- Start Section-->
        <section class="relative lg:py-24 py-16">
            <div class="container">
                <div class="grid md:grid-cols-12 grid-cols-1 items-center gap-6">
                    <div class="lg:col-span-7 md:col-span-6">
                        <img src="theme8/images/contact.svg" alt="">
                    </div>

                    <div class="lg:col-span-5 md:col-span-6">
                        <div class="lg:ms-5">
                            <div class="bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-gray-700 p-6">
                                <h3 class="mb-6 text-2xl leading-normal font-semibold">
                                  {{ $t('Get in touch !') }}</h3>

                                <form  @submit.prevent="submitmessage" 
                                @keydown="form.onKeydown($event)">
                                    <p class="mb-0" id="error-msg"></p>
                                    <div id="simple-msg"></div>
                                    <div class="grid lg:grid-cols-12 grid-cols-1 gap-3">
                                        <div class="lg:col-span-6">
                                            <label for="name" class="font-semibold">{{ $t('Your Name') }}:</label>
                                            <input 
                                            name="name" 
                                            id="name" 
                                            type="text" 
                                            class="mt-2 w-full py-2 px-3 h-10 bg-transparent
                                             dark:bg-slate-900 dark:text-slate-200
                                              rounded outline-none border border-gray-100
                                               dark:border-gray-800 focus:ring-0"
                                               :placeholder="$t('name')"
                                                v-model="form.name"
                                                  required
                                                >
                                        </div>
        
                                        <div class="lg:col-span-6">
                                            <label for="email" class="font-semibold">{{$t('Your Email')}}:</label>
                                            <input 
                                            name="email" 
                                            id="email" 
                                            type="email" 
                                            class="mt-2 w-full py-2 px-3 h-10 
                                            bg-transparent dark:bg-slate-900 
                                            dark:text-slate-200 rounded 
                                            outline-none border border-gray-100 
                                            dark:border-gray-800 focus:ring-0" 
                                            :placeholder="$t('Email')"
                                             v-model="form.email"
                                            required
                                            >
                                        </div>

                                        <div class="lg:col-span-12">
                                            <label for="subject" class="font-semibold">{{$t('Your Question')}}:</label>
                                            <input 
                                            name="subject" 
                                            id="subject" 
                                            class="mt-2 w-full py-2 px-3 h-10 bg-transparent 
                                            dark:bg-slate-900 dark:text-slate-200 rounded 
                                            outline-none border border-gray-100 dark:border-gray-800
                                             focus:ring-0" :placeholder="$t('Your Question') +':'">
                                        </div>
    
                                        <div class="lg:col-span-12">
                                            <label for="comments" class="font-semibold">{{$t('message')}}:</label>
                                            <textarea 
                                            name="comments" 
                                            id="comments" 
                                            class="mt-2 w-full py-2 px-3 h-28 
                                            bg-transparent dark:bg-slate-900
                                             dark:text-slate-200 rounded 
                                             outline-none border border-gray-100
                                              dark:border-gray-800 focus:ring-0" 
                                              :placeholder="$t('message')"
                                               v-model="form.message"
                                                required
                                              
                                              ></textarea>
                                        </div>
                                    </div>
                                    <button type="submit" 
                                    id="submit" name="send"
                                     class="py-2 px-5 inline-block tracking-wide
                                      align-middle duration-500 text-base text-center
                                       bg-orange-500 text-white rounded-md mt-2"
                                       :disabled="form.busy"
                                       >{{ $t("SendMessage") }}</button>

                                </form>
                                <div v-if="loading_msg" class="empty text-center center-text">
                 
                               <img src="/pet_shop/assets/images/ajax-loader.gif"/>
                           <br>

                       </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div><!--end container-->
            
            <div class="container lg:mt-24 mt-16">
                <div class="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-6">
                    <div class="text-center px-6">
                        <div class="relative text-transparent">
                            <div class="size-20 bg-orange-500/5 text-orange-500 rounded-xl text-2xl flex align-middle justify-center items-center mx-auto shadow-sm dark:shadow-gray-800">
                                <i v-html="phone"></i>
                            </div>
                        </div>

                        <div class="content mt-7">
                            <h5 class="title h5 text-lg font-semibold">{{ $t('Phone') }}</h5> 
                            <div class="mt-5" dir="ltr">
                                <a 
                                :href="'tel:'+DBitems.social.phone" 
                                class="text-orange-500 font-medium social_a">
                                {{ DBitems.social.phone }}</a>
                                <br>
                                <a v-if="DBitems.social.other_phone" 
                                :href="'tel:'+DBitems.social.other_phone"
                                class="text-orange-500 font-medium social_a">
                                {{ DBitems.social.other_phone }}</a>

                            </div>
                        </div>
                    </div>

                    <div class="text-center px-6">
                        <div class="relative text-transparent">
                            <div class="size-20 bg-orange-500/5 text-orange-500 rounded-xl text-2xl flex align-middle justify-center items-center mx-auto shadow-sm dark:shadow-gray-800">
                                <i v-html="mail"></i>
                            </div>
                        </div>

                        <div class="content mt-7">
                            <h5 class="title h5 text-lg font-semibold">{{ $t('Email') }}</h5>
                            
                            
                            <div class="mt-5">
                                <a :href="'mailto:'+DBitems.social.email" 
                                class="text-orange-500 font-medium social_a">
                               {{ DBitems.social.email}}</a>
                            </div>
                        </div>
                    </div>

                    <div class="text-center px-6">
                        <div class="relative text-transparent">
                            <div class="size-20
                             bg-orange-500/5
                             text-orange-500 rounded-xl text-2xl 
                             flex align-middle justify-center
                              items-center mx-auto shadow-sm 
                            dark:shadow-gray-800 social_a">
                                <i v-html="map_pin" style="color:white"></i>
                            </div>
                        </div>

                        <div class="content mt-7">
                            <h5 class="title h5 text-lg font-semibold">{{ $t('address') }}</h5>
                            <p class="text-slate-400 mt-3">
                                {{  DBitems.social.address}}
                            </p>
                            
                           
                        </div>
                    </div>
                </div><!--end grid-->
            </div><!--end container-->
        </section><!--end section-->
        <!-- End Section-->
    </div>
</template>
<script>
import Form from "vform";
import { AlertErrors, AlertSuccess } from "vform/src/components/bootstrap5";
import { HalfCircleSpinner, HollowDotsSpinner,CirclesToRhombusesSpinner } from "epic-spinners";

export default {
  components: {
    AlertErrors,
    AlertSuccess,
    HalfCircleSpinner,
     HollowDotsSpinner,
     CirclesToRhombusesSpinner
  },
  data: function() {
    return {
      loading: false,
      DBitems: null,

      form: new Form({
        name: null,
        email: null,
        message: null
      }),

      marker: { position: {  
        lat: 51.677878,
          lng: 30.67676 } },
      center: { lat: 51.677878,
          lng: 30.67676 },
      mapOptions: {
        disableDefaultUI: true
      }
    };
  },
  created() {
    this.$store.state.topbar='topbar_dark';
    this.$store.state.navbar='navbar_dark navbar_top';
    this.loading = true;
    this.axios
      .get(
        this.$store.state.base_api_url + this.$store.storeName + "/contact_us",
        {
          headers: {
            "Accept-Language": this.$i18n.locale
          }
        }
      )
      .then(response => {
        this.DBitems = response.data.data;
        this.marker.position = {
          lat: this.DBitems.social.lat,
          lng: this.DBitems.social.long
        };
        this.center = {
          lat: this.DBitems.social.lat,
          lng: this.DBitems.social.long
        };
      })
      .then(() => (this.loading = false));
      if(this.$i18n.locale=='ar')
      {
      document.title = this.$store.state.title_ar;
      document.getElementById("description_meta").content=this.$store.state.description_ar;
      document.getElementById("keywords_meta").content=this.$store.state.keyword_ar;
      }
      else{
        document.title = this.$store.state.title_en;
      document.getElementById("description_meta").content=this.$store.state.description_en;
      document.getElementById("keywords_meta").content=this.$store.state.keyword_en;
      }
  },
  computed: {
    mail: function () {
      return feather.toSvg('mail')
    },
    phone: function () {
      return feather.toSvg('phone')
    },
    map_pin: function () {
      return feather.toSvg('map-pin')
    },
  },
  methods: {
    updatedata() {
      this.loading = true;
      this.axios
        .get(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/contact_us",
          {
            headers: {
              "Accept-Language": this.$i18n.locale
            }
          }
        )
        .then(response => {
          this.DBitems = response.data.data;
        })
        .then(() => (this.loading = false));
      this.$store.state.langchange = false;
    },
    async submitmessage() {
      this.loding_msg=true;
      const response = await this.form
        .post(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/send_message",
          {
            message: this.form.message,
            name: this.form.name,
            email: this.form.email
          },
          {
            headers: {
              "Accept-Language": this.$i18n.locale
            }
          }
        )
        .then(response => {
          this.loading_msg=false;
          if (response.data.code == "1") {
            this.form.message = "";
            this.form.name = "";
            this.form.email = "";
            console.log(response.data.message);
          }
          if (response.data.error) {
            this.errorMessage = response.data.message;
          }
        });
    },

    //sets the position of marker when dragged
    handleMarkerDrag(e) {
      this.marker.position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
    },
    //Moves the map view port to marker
    panToMarker() {
      this.$refs.mapRef.panTo(this.marker.position);
      this.$refs.mapRef.setZoom(18);
    },
    //Moves the marker to click position on the map
    handleMapClick(e) {
      this.marker.position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
      console.log(e);
    }
  }
};
</script>