<template>
  <div class="container" id="container_order">
    <h2 style="text-align: center; ">{{ $t('profile') }}</h2>
    <profile_header></profile_header>

    <!--  Edite profile -->

    <div>
      <div class="container">
        <form style=" margin-top: 3%; " autocomplete="false" @submit.prevent="submitInfo"
          @keydown="form.onKeydown($event)">
          <div class="div2_address">
            <label class="lebal">{{ $t('Full Name') }}</label>
            <!-- <input type="text" class="form-control" value="Jenny Wilson" 
        
            > -->
            <div class="input-group mb-3">
              <input type="text" class="form-control" id="name" autocomplete="false" name="name" v-model="form.name"
                maxlength="30">
              <div class="input-group-append">
                <i class="fa fa-pencil icon"></i>

              </div>
            </div>
          </div>
          <div class="div2_address">
            <label class="lebal">{{ $t('Phone') }}</label>
            <!-- <input type="text" class="form-control" value="Jenny Wilson" 
        
            > -->
            <div class="input-group mb-3" dir="ltr">
              <span class="flag " dir="ltr">
                <h2>{{ $store.state.country_code }}</h2>
                <img :src="this.$store.state.country_flag">
              </span>
              <input v-if="this.$store.state.auth == 0" type="text" class="form-control phone_profile" name="phone"
                autocomplete="false" v-model="phone" placeholder="xxxx xxxx" required
                :minlength=$store.state.country_length :maxlength=$store.state.country_length>
              <input v-else type="text"  v-model="phone" class="form-control phone_profile" name="phone" autocomplete="false" valu="phone"
                placeholder="xxxx xxxx" required :minlength=$store.state.country_length
                :maxlength=$store.state.country_length readonly>
              <div  v-if="this.$store.state.auth == 0" class="input-group-append" dir="ltr">
                <i class="fa fa-pencil icon"></i>

              </div>
            </div>
          </div>
          <div class="div2_address">
            <label class="lebal">{{ $t('Email Address') }}</label>
            <!-- <input type="text" class="form-control" value="Jenny Wilson" 
        
            > -->
            <div class="input-group mb-3">
              <input v-if="this.$store.state.auth != 0" type="email" class="form-control" autocomplete="false"
                name="email" v-model="form.email" required maxlength="50">
                <div  v-if="this.$store.state.auth == 1" class="input-group-append">
                  <i class="fa fa-pencil icon"></i></div>
              <input v-else type="text" class="form-control" :value="form.email" readonly>
            </div>
          </div>

          <button class="butt_green center_align" type="submit" :disabled="form.busy">{{ $t('save') }}</button>
          <div v-if="errorMessage != ''" style="
                      text-align: center;
                      color: var(--primary-color);
                      padding: 5px;
                    ">
            <p style="margin: 0">{{ errorMessage }}</p>
          </div>

        </form>
      </div>

    </div>

  </div>
</template>

<script>

import Form from "vform";
import profile_header from "../components/profile_header.vue";
import CodeInput from "vue-verification-code-input";

import Vodal from "vodal";

import "vodal/common.css";
import "vodal/slide-down.css";
import "vodal/flip.css";
import "vodal/slide-left.css";
import { HalfCircleSpinner, HollowDotsSpinner, CirclesToRhombusesSpinner } from "epic-spinners";

export default {
  components: {
    Vodal,
    CodeInput,
    profile_header,
    HalfCircleSpinner,
    HollowDotsSpinner,
    CirclesToRhombusesSpinner

  },
  data: function () {
    return {
      //DBitems: null,
      forgetVodal: false,
      codeVodal: false,
      newPassVodal: false,

      errorMessageVodal: "",
      passemail: "",
      Newpassword: null,
      checkcomplete: false,
      finalcode: null,
      phone: null,
      errorMessage: "",
      form: new Form({
        email: null,
        name: null,
        phone: null,
      }),
      show: false,
      show2: false,
    };
  },

  created() {
    if (this.$route.params.lang && this.$route.params.lang != this.$i18n.locale) {
      this.$i18n.locale = this.$route.params.lang; this.$store.state.UnitPrice = this.$store.state.unitAR
      if (this.$i18n.locale == 'en') {
        document.documentElement.setAttribute("dir", "ltr");
        let h = document.querySelector('body');
      }
    }

    this.axios
      .get(
        this.$store.state.base_api_url + this.$store.storeName + "/profile",
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
            Authorization:
              "Bearer " + this.$localStorage.get(this.$store.storeName)
          }
        }
      )
      .then(response => {
        this.form.email = response.data.data.email;
        this.form.name = response.data.data.name;
        this.phone = response.data.data.phone.substr(4);
        console.log('phone ' + this.phone);
        this.$localStorage.set(
          this.$store.user_phone,
          data.data.phone);
        console.log('******* ', response.data.data)
      })
      .catch(error => {

      });
    if (this.$i18n.locale == 'ar') {
      document.title = this.$store.state.title_ar;
      document.getElementById("description_meta").content = this.$store.state.description_ar;
      document.getElementById("keywords_meta").content = this.$store.state.keyword_ar;
    }
    else {
      document.title = this.$store.state.title_en;
      document.getElementById("description_meta").content = this.$store.state.description_en;
      document.getElementById("keywords_meta").content = this.$store.state.keyword_en;
    }
  },
  methods: {

    async submitInfo() {
      this.form.phone = this.$store.state.country_code + this.phone;

      const response = await this.axios
        .post(
          this.$store.state.base_api_url +
          this.$store.storeName +
          "/profile/update",
          {
            email: this.form.email,
            name: this.form.name,
            phone: this.form.phone
          },
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization:
                "Bearer " + this.$localStorage.get(this.$store.storeName)
            }
          }
        )
        .then(response => {
          if (response.data.code == "1") {
            this.errorMessage = response.data.message;
            setTimeout(() => {
              this.errorMessage = "";
            }, 3000);
          } else if (response.data.code == "-1") {
            this.errorMessage = response.data.message;
            setTimeout(() => {
              this.errorMessage = "";
            }, 3000);
          }
          if (response.data.error) {
            this.errorMessage = response.data.error;
            setTimeout(() => {
              this.errorMessage = "";
            }, 3000);
          }
        })
        .catch(error => {
          if (error.response.status == "401") {
            this.$localStorage.remove(this.$store.storeName);
            window.location.href = "/";
          }
        });
    },

  },
  watch: {
    langchange() {
      this.axios
        .get(
          this.$store.state.base_api_url + this.$store.storeName + "/profile",
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization:
                "Bearer " + this.$localStorage.get(this.$store.storeName)
            }
          }
        )
        .then(response => {
          this.form.email = response.data.data.email;
          this.form.name = response.data.data.name;
          this.phone = response.data.data.phone.substr(4);
          console.log('phone ' + this.phone);
          //console.log(this.form)
        })
        .catch(error => {

        });
    }
  }
};
</script>