<template>
    <section  id="section_login" class="login-page">
        <div class="container">
            <div class="row">
                <div  v-if="!registerUserInfo"class="col-lg-8 col-md-8 col-12">
                    <h3 class="color_font">{{$t('Plant Your Dream')}}</h3>
                    <h4 v-if="this.$store.state.auth == 0">{{$t('Please Enter The Verification Code Sent to your Email')}}</h4>
                    <h4 v-else>{{$t('Please Enter The Verification Code Sent to your phone')}}</h4>
                
          <div >
          <input
            :loading="false"
            required
           class="input-field code_input"
           type="text"
           minlength="6"
           maxlength="6"
          v-model="finalcode"
          />
          <div v-if="loading" class="empty text-center center-text">
                 
                 <img src="/pet_shop/assets/images/ajax-loader.gif"/>
                           <br>

                       </div>
          <div
          v-if="validateMessage != ''"
          style="text-align: center; margin-top: 10px; margin-bottom: 0"
        >
          <span style="color: red; margin: 0">{{ validateMessage }}</span>
        </div>
        </div>
                    <p style="font-size: 20px ; margin-top: 4%;" >
                       
                      <p v-if="countDown==0" > {{ $t('Didn’t receive the verification code') }}</p>
                     <a  v-if="countDown==0" 
                      @click="ResendCode()" 
                     class="color_font  " 
                     style="font-size: 20px; ;   ">
                    {{ $t('ResendCode') }}
                    </a>
                    
                    <span style="font-size: 17px;
                     color: #c9c9c9;
                      padding-left: 10%;" class="counter_login">
                         {{ countDown }} 
                        {{$t('second')}}</span></p>
                    <button type="submit"
                    @click="checkCode" 
                    :disabled="countDown==0" 
                    class="butt_green  login_but" 
                    style="margin-top: 5%;"> 
                    {{ $t('submit') }}
                    </button>        
                </div>
                <div  class="col-lg-8 col-md-8 col-12" v-if="registerUserInfo">
                 <user-info :phone="phone" :email="email"></user-info>
                 </div>
                <div class="col-lg-4 col-md-4 col-12">
                 <img src="/theme10/images/code_img.png"  
                 class= "img_code"   
                  >
                </div>
            </div>
        </div>




    </section>
</template>
<script>
import UserInfo from "../components/userinfo.vue";
import CodeInput from "vue-verification-code-input";

export default {
  components: { CodeInput, UserInfo },
  props: {
    phone: String,
    email: String,
  },
  data: function () {
    return {
      registerUserInfo: false,
      checkcomplete: false,
      validateMessage: "",
      validateMessage1: "",
      finalcode: null,
      countDown: 90,
      loading:false
    };
  },
  created() {
    if(this.$route.params.lang&&this.$route.params.lang!=this.$i18n.locale){
      this.$i18n.locale=this.$route.params.lang;this.$store.state.UnitPrice=this.$store.state.unitAR
      if(this.$i18n.locale=='en')
        {document.documentElement.setAttribute("dir", "ltr");
        let h = document.querySelector('body');
      }
    }
    this.countDownTimer();
  },
  methods: {
    checkCode() {
      if (this.finalcode) {
        if (this.$store.state.auth == 1) {
          this.loading=true
        this.axios
          .post(
            this.$store.state.base_api_url +
              this.$store.storeName +
              "/register_code",
              {
                /*/register_code*/
                phone: this.phone,
                code: this.finalcode,
                fcm_token: this.$store.state.fsm_token
              },
            {
              headers: {
                "Accept-Language": this.$i18n.locale,
              },
            }
          )
          .then((response) => {
            this.loading=false
            if (response.data.code == "-1") {
              //  console.log( response.data.code);
              this.validateMessage = response.data.message;
              setTimeout(() => {
                this.validateMessage = "";
              }, 3000);
            } else if (response.data.code == "1") {
              this.registerUserInfo = true;
              if(response.data.data.token)
              this.$localStorage.set(
                  this.$store.storeName,
                  response.data.data.token
                );
                
            }
          });
        }else {
          this.loading=true
          ///for register by email
          this.axios
            .post(
              this.$store.state.base_api_url +
                this.$store.storeName +
                "/register_code/byemail",
              {
                email: this.email,
                code: this.finalcode,
              },
              {
                headers: {
                  "Accept-Language": this.$i18n.locale,
                },
              }
            )
            .then((response) => {
              this.loading=false
              if (response.data.code == "-1") {
                //  console.log( response.data.code);
                this.validateMessage = response.data.message;
                setTimeout(() => {
                  this.validateMessage = "";
                }, 3000);
              } else if (response.data.code == "1") {
                this.registerUserInfo = true;
                
              }
            });
        }
      } else {
        if (this.$i18n.locale == "en") {
          this.validateMessage = "your code isn't complete";
          setTimeout(() => {
            this.validateMessage = "";
          }, 3000);
        } else {
          this.validateMessage = "يرجى إكمال كافة الحقول";
          setTimeout(() => {
            this.validateMessage = "";
          }, 3000);
        }
      }
    },
    ResendCode() {
        this.loading=true
      if (this.$store.state.auth == 1) {
      
      this.axios
        .post(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/app/genret_code",
          {
            phone: this.phone,
            //country: this.form.country,
          },
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
            },
          }
        )
        .then((response) => {
          this.loading=false
          if (response.data.code == "-1") {
            this.validateMessage = response.data.message;
            setTimeout(() => {
              this.validateMessage = "";
            }, 3000);
          } else if (response.data.code == "1") {
            this.countDown = 90;
            this.countDownTimer();
            this.validateMessage1 = response.data.message;
            setTimeout(() => {
              this.validateMessage1 = "";
            }, 3000);
          }
        });
      }else {
        this.axios
          .post(
            this.$store.state.base_api_url +
              this.$store.storeName +
              "/genret_code/byemail",
            {
              email: this.email,
              //country: this.form.country,
            },
            {
              headers: {
                "Accept-Language": this.$i18n.locale,
              },
            }
          )
          .then((response) => {
            this.loading=false
            if (response.data.code == "-1") {
              this.validateMessage = response.data.message;
              setTimeout(() => {
                this.validateMessage = "";
              }, 3000);
            } else if (response.data.code == "1") {
              this.countDown = 90;
              this.countDownTimer();
              this.validateMessage1 = response.data.message;
              setTimeout(() => {
                this.validateMessage1 = "";
              }, 3000);
            }
          });
      }
    },
    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1;
          this.countDownTimer();
        }, 1000);
      }
    },
    onChange(v) {
      //console.log("onChange ", this.phone);
      this.checkcomplete = false;
    },
    onComplete(v) {
      //console.log("onComplete ", v);
      this.finalcode = v;
      this.checkcomplete = true;
    },
  },
};
</script>
